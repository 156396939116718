<script>
/* eslint-disable */
export default {
  name: "open",
  data() {
    return {
      bicSelect: 1,
    }
  },
  methods: {
    /** 跳转路径 */
    toPath(url) {
      this.$router.push({
        path: url,
      })
    }
  }
}
</script>

<template>
  <div>
    <main-header :selected="6"></main-header>
    <div class="container">
      <div class="head-img-container">
        <img class="head-img" :src="require('/src/assets/images/platforms/open/bg.jpg')" alt>
        <div class="head-content-box">
          <div class="head-txt-box">
            <div class="head-title-m-box">
              <img class="head-icon" :src="require('/src/assets/images/platforms/open/icon.png')" alt>
              <div class="head-txt-title-m">互联网医院</div>
            </div>
            <div class="head-txt-title">人工智能互联网医院解决方案</div>
            <div class="head-txt-content">利用人工智能技术和应用，赋能全流程互联网医疗，助力互联网医院全面进入3.0人工智能时代。患者就医更精准、更顺畅，医生在线诊疗更便捷，服务运营更高效，线上线下结合更紧密。</div>
            <div class="head-btn-box">
              <el-button class="head-button">申请合作</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-body">

      <!-- 大卡片组件 -->
<!--      <div class="bic-container">-->
<!--        <div class="bi-txt">-->
<!--          <div class="bi-txt-title">应用场景</div>-->
<!--          &lt;!&ndash;          <div class="bi-txt-tip">示例标题示例标题</div>&ndash;&gt;-->
<!--        </div>-->
<!--        <div class="bic-card">-->
<!--          <div class="bic-main">-->
<!--            <div class="bic-main-item" v-show="bicSelect === 1">-->
<!--              <img class="bic-img" :src="require('/src/assets/images/platforms/imt-1.jpg')" alt>-->
<!--              <div class="bic-main-text-box">-->
<!--                <div class="bic-m-t-title-box">-->
<!--                  <div class="bic-m-t-line"></div>-->
<!--                  <div class="bic-m-t-title">智慧医院</div>-->
<!--                </div>-->
<!--                <div class="bic-m-t-text-box">-->
<!--                  <div class="bic-m-t-item">-->
<!--                    <div class="bic-m-t-text-before"></div>-->
<!--                    <div class="bic-m-t-text-content">就诊前进行患者病情信息收集，提高问诊效率</div>-->
<!--                  </div>-->
<!--                  <div class="bic-m-t-item">-->
<!--                    <div class="bic-m-t-text-before"></div>-->
<!--                    <div class="bic-m-t-text-content">可对接EMR系统，核对无误直接写入系统</div>-->
<!--                  </div>-->
<!--                  <div class="bic-m-t-item">-->
<!--                    <div class="bic-m-t-text-before"></div>-->
<!--                    <div class="bic-m-t-text-content">辅助医生进行患者病情分析及风险评估</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="bic-main-item" v-show="bicSelect === 2">-->
<!--              <img class="bic-img" :src="require('/src/assets/images/platforms/imt-2.jpg')" alt>-->
<!--              <div class="bic-main-text-box">-->
<!--                <div class="bic-m-t-title-box">-->
<!--                  <div class="bic-m-t-line"></div>-->
<!--                  <div class="bic-m-t-title">分级诊疗</div>-->
<!--                </div>-->
<!--                <div class="bic-m-t-text-box">-->
<!--                  <div class="bic-m-t-item">-->
<!--                    <div class="bic-m-t-text-before"></div>-->
<!--                    <div class="bic-m-t-text-content">根据采集的信息判断患者病情危重程度实现分流/分级就诊</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="bic-main-item" v-show="bicSelect === 3">-->
<!--              <img class="bic-img" :src="require('/src/assets/images/platforms/imt-3.jpg')" alt>-->
<!--              <div class="bic-main-text-box">-->
<!--                <div class="bic-m-t-title-box">-->
<!--                  <div class="bic-m-t-line"></div>-->
<!--                  <div class="bic-m-t-title">互联网医院</div>-->
<!--                </div>-->
<!--                <div class="bic-m-t-text-box">-->
<!--                  <div class="bic-m-t-item">-->
<!--                    <div class="bic-m-t-text-before"></div>-->
<!--                    <div class="bic-m-t-text-content">就诊前进行患者病情信息收集，提高问诊效率</div>-->
<!--                  </div>-->
<!--                  <div class="bic-m-t-item">-->
<!--                    <div class="bic-m-t-text-before"></div>-->
<!--                    <div class="bic-m-t-text-content">在线诊疗诊前病情信息收集</div>-->
<!--                  </div>-->
<!--                  <div class="bic-m-t-item">-->
<!--                    <div class="bic-m-t-text-before"></div>-->
<!--                    <div class="bic-m-t-text-content">可对接EMR系统，诊后核对直接上传病历</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="bic-main-item" v-show="bicSelect === 4">-->
<!--              <img class="bic-img" :src="require('/src/assets/images/platforms/imt-4.jpg')" alt>-->
<!--              <div class="bic-main-text-box">-->
<!--                <div class="bic-m-t-title-box">-->
<!--                  <div class="bic-m-t-line"></div>-->
<!--                  <div class="bic-m-t-title">在线诊疗平台</div>-->
<!--                </div>-->
<!--                <div class="bic-m-t-text-box">-->
<!--                  <div class="bic-m-t-item">-->
<!--                    <div class="bic-m-t-text-before"></div>-->
<!--                    <div class="bic-m-t-text-content">问诊前进行患者病情信息收集，提高在线问诊效率</div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="bic-aside">-->
<!--            <div class="bic-button" @click="bicSelect = 1" :class="{'bic-button-selected': bicSelect === 1}">-->
<!--              <p class="bic-button-txt">智慧医院</p>-->
<!--            </div>-->
<!--            <div class="bic-button" @click="bicSelect = 2" :class="{'bic-button-selected': bicSelect === 2}">-->
<!--              <p class="bic-button-txt">分级诊疗</p>-->
<!--            </div>-->
<!--            <div class="bic-button" @click="bicSelect = 3" :class="{'bic-button-selected': bicSelect === 3}">-->
<!--              <p class="bic-button-txt">互联网医院</p>-->
<!--            </div>-->
<!--            <div class="bic-button" @click="bicSelect = 4" :class="{'bic-button-selected': bicSelect === 4}">-->
<!--              <p class="bic-button-txt">在线诊疗平台</p>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->

      <!-- 大图片组件 -->
            <div class="big-img-box">
              <div class="bi-txt">
                <div class="bi-txt-title">解决方案全貌</div>
<!--                <div class="bi-txt-tip">示例标题示例标题</div>-->
              </div>
              <img class="bi-img" :src="require('/src/assets/images/platforms/open/img-1.jpg')" alt>
            </div>

      <!-- 左右图文组件 -->
      <div class="img-txt-container-left">
        <div class="bi-txt">
          <div class="bi-txt-title">方案特色亮点</div>
          <!--          <div class="bi-txt-tip">示例标题示例标题</div>-->
        </div>
        <div class="t-2-body">
          <div class="t-2-cr-box">
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">就诊全程智能化赋能，医生服务更高效</div>
              </div>
              <div class="t-2-txt-content">智能化应用覆盖“诊前-诊中-诊后”完整医患服务流程，各个环节均可提升医生服务效率，帮助提升患者就医体验，辅助运营管理者提升运营质量、降本增效。</div>
            </div>
          </div>
          <img class="t-2-img" alt :src="require('/src/assets/images/platforms/open/img-2.png')">
        </div>
        <div class="t-2-body ">
          <div class="t-2-cr-box">
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">AI在线医疗质控，满足日益提升的监管标准</div>
              </div>
              <div class="t-2-txt-content">为满足《互联网诊疗监管细则（试行）》中对线上诊疗问诊过程中医生的诊疗行为、医疗服务质量、线上问诊病历产生的进一步质控和监管的要求，左医在事中、事后提供完善、全面的AI在线医疗质控方案。对问诊中的文字对话、音视频实时检测，在事中进行提醒或拦截，辅助医生完成合规的线上问诊病历，并事后统计分析，极大提升质控效率。</div>
            </div>
          </div>
          <img class="t-2-img" alt :src="require('/src/assets/images/platforms/open/img-3.png')">
        </div>
        <div class="t-2-body ">
          <div class="t-2-cr-box">
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">智能诊后管理，线上线下一体化</div>
              </div>
              <div class="t-2-txt-content">将线下就诊患者自动引导至线上互联网医院复诊，形成流量闭环。同时，利用线上智能化技术开展远程随访管理，降低随访服务运营成本，提升服务效率与服务规模。为每个医护人员都配备一个AI随访机器人助手，覆盖多种随访场景，并支持AI电话外呼随访。</div>
            </div>
          </div>
          <img class="t-2-img" alt :src="require('/src/assets/images/platforms/open/img-4.png')">
        </div>
        <div class="t-2-body ">
          <div class="t-2-cr-box">
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">互联网医院运营，新媒体宣教+私域患者管理</div>
              </div>
              <div class="t-2-txt-content">帮助互联网医院进行线上短视频新媒体运营，打造医生/科室IP，提升传播范围和认知教育效果。内容结合智能风险筛查、智能导医等自测和转化工具，有效完成精准患者的服务转化和私域流量留存。帮助医院进行精准的患者分层，提供针对性的患者运营服务，线上线下闭环，提高慢病患者长期粘性和服务满意度，提升互联网医院运行价值。</div>
            </div>
          </div>
          <img class="t-2-img" alt :src="require('/src/assets/images/platforms/open/img-5.png')">
        </div>
      </div>

      <!--      <div class="img-txt-container-left">-->
      <!--        <div class="t-2-body">-->
      <!--          <div class="t-2-cr-box">-->
      <!--            <div class="t-2-cr">-->
      <!--              <div class="t-2-cr-title">示例标题</div>-->
      <!--              <div class="t-2-cr-text">-->
      <!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="t-2-cr">-->
      <!--              <div class="t-2-cr-title">示例标题</div>-->
      <!--              <div class="t-2-cr-text">-->
      <!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="t-2-cr">-->
      <!--              <div class="t-2-cr-title">示例标题</div>-->
      <!--              <div class="t-2-cr-text">-->
      <!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="t-2-cr">-->
      <!--              <div class="t-2-cr-title">示例标题</div>-->
      <!--              <div class="t-2-cr-text">-->
      <!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <img class="t-2-img" alt :src="require('/src/assets/images/diagnosis/remote/imt-2.jpg')">-->
      <!--        </div>-->
      <!--      </div>-->

      <!-- 卡片组件 -->
      <div class="card-container">
        <div class="bi-txt">
          <div class="bi-txt-title">产品优势</div>
          <!--          <div class="bi-txt-tip">示例标题示例标题</div>-->
        </div>
        <div class="card-box">
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/platforms/open/cr-1.png')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">简化患者匹配流程</div>
                <p>医疗对话机器人和智能医助可有效提升医院服务可及性，免去患者一些不必要的现场就诊。同时根据患者真实需求，精准匹配临床医生，高效进行在线诊疗</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/platforms/open/cr-2.png')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">医疗专家的好帮手</div>
                <p>人工智能医助在线上问诊、辅助诊断治疗、诊后疾病康复、个人健康管理等多个环节，高效精准辅助医生，打造人机协同的在线诊疗服务新模式</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/platforms/open/cr-3.png')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">不仅快、准，还很“全面”</div>
                <p>现代医疗对患者身体各处的状态监控仍然处在“盲人摸象”阶段，而人工智能可以根据捕捉到的一些细微病症变化，分析其背后的多维度信息，完成“一叶知秋”的诊断预警</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/platforms/open/cr-4.png')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">AI赋能实现互联网医院的降本增效</div>
                <p>人工智能可通过精准导诊、智能医助辅助问诊、智能患者管理机器人、任务流程自动化等核心应用，实现将重复性、标准化工作交给AI医助完成，提前整理好患者信息支撑医生临床决策。依靠人机协同大幅降低运营成本，提高医生诊疗效率和服务规模</p>
              </div>
            </div>
          </el-card>
        </div>
      </div>

    </div>
    <main-footer></main-footer>
  </div>
</template>

<style scoped lang="scss">
@import "~@/styles/platforms/open";
</style>

<script>
/* eslint-disable */
export default {
  name: "search",
  data() {
    return {
      /**
       * 样例商品
       * example-1;50惠育发
       * example-2;新碧防晒;日用防晒
       * example-3;肌研极润;乳液
       * example-4;手霜及身体乳;手霜
       * example-5;肌研极润;日用防晒
       * example-6;肌研极润;乳液
       * example-7;肌研极润;洁面
       * example-8;肌研极润;卸妆
       */
      // 产品列表数组（模拟获取后端接口）
      productData: [
        {
          id: 2,
          name: "example-2",
          label: "双重保湿水感防晒露SPF50",
          tip: "防晒黑，防晒干，清透薄",
          price: 109.90,
          series: 3,
          demand: 1,
          imgURL: require('@/assets/images/product/example-2-list.jpg'),
        },
        {
          id: 3,
          name: "example-3",
          label: "肌研极润保湿乳液",
          tip: "锁水8小时，透光一整天",
          price: 105.00,
          series: 1,
          demand: 2,
          imgURL: require('@/assets/images/product/example-3-list.jpg'),
        },
        {
          id: 4,
          name: "example-4",
          label: "水分护手霜",
          tip: "舒缓修复，水润不黏",
          price: 20.90,
          series: 4,
          demand: 3,
          imgURL: require('@/assets/images/product/example-4-list.jpg'),
        },
        {
          id: 5,
          name: "example-5",
          label: "肌研清透美白隔离乳（清爽型）",
          tip: "清透隔离，打造自然妆容",
          price: 130.90,
          series: 1,
          demand: 1,
          imgURL: require('@/assets/images/product/example-5-list.jpg'),
        },
        {
          id: 6,
          name: "example-6",
          label: "肌研极润特浓保湿乳液",
          tip: "弹润紧致，柔韧肤质",
          price: 110.00,
          series: 1,
          demand: 2,
          imgURL: require('@/assets/images/product/example-6-list.jpg'),
        },
        {
          id: 7,
          name: "example-7",
          label: "肌研极润洁面泡沫",
          tip: "洗出如敷面膜后的水润柔软肌",
          price: 90.00,
          series: 1,
          demand: 5,
          imgURL: require('@/assets/images/product/example-7-list.jpg'),
        },
        {
          id: 8,
          name: "example-8",
          label: "肌研极润净透卸妆油",
          tip: "4种修复美容油，温和卸除彩妆",
          price: 130.00,
          series: 1,
          demand: 6,
          imgURL: require('@/assets/images/product/example-8-list.jpg'),
        },
        {
          id: 9,
          name: "example-9",
          label: "阿茉尔蜂蜜炭黑面膜",
          tip: "吸走油脂污垢，净毛孔",
          price: 99.90,
          series: 2,
          demand: 4,
          imgURL: require('@/assets/images/product/example-9-list.jpg'),
        },
      ],
      // 过滤后的产品数组
      productDataHandled: [],
      // 搜索关键字
      searchKey: "",

    }
  },
  created() {
    this.productDataHandled = this.productData
  },
  methods: {
    /** 选择商品 */
    onProductClick(id) {
      this.$router.push({
        path: '/detail',
        query: {
          productId: id,
        }
      })
    },
    /** 搜索 */
    onInputChange(str) {
      this.productDataHandled = this.productData.filter((item) => {
        return item.label.includes(str)
      })
    }
  }
}
</script>

<template>
  <div>
    <main-header :selected="8"></main-header>
    <div class="container">
      <div class="search-container">
        <el-input
            class="search-input"
            placeholder="请输入产品名称"
            prefix-icon="el-icon-search"
            clearable
            @input="onInputChange"
            v-model="searchKey">
        </el-input>
      </div>
      <div class="search-result">
        符合搜索条件的产品：<span class="search-re-num">{{productDataHandled.length}}</span>
      </div>
      <div class="select-list-container">
        <div class="se-li-list">
          <div class="se-li-card" v-for="item in productDataHandled" :key="item.id" @click="onProductClick(item.id)">
            <img :src="item.imgURL" alt>
            <div>{{item.label}}</div>
            <div class="card-price">￥{{item.price.toFixed(2)}}</div>
            <div class="card-line"></div>
            <div class="card-tip">{{item.tip}}</div>
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<style scoped lang="scss">
@import "~@/styles/search";
</style>

<script>
/* eslint-disable */
import {apiGetRFIDetail, apiGetRFIList} from "@/common/api";

export default {
  name: "resolve",
  data() {
    return {
      activeName: null,
      // yearList: [
      //   {
      //     label: "2024年",
      //     value: 2024
      //   }
      // ],
      yearList: [],
      selectYear: null,
      listData: [],
      filterData: [],
      detailList: [],

      isPopCodeShowDiag: false
    }
  },
  created() {
    this.getList();
  },
  methods: {
    onYearSelect(year) {
      this.selectYear = year;
    },
    /** 获取列表 */
    async getList() {
      const res = await apiGetRFIList();
      if(res.data.code === 200) {
        this.listData = res.data.rows;
        this.yearFilter();
        this.selectYear = this.yearList[0].value;
      }
    },
    /** 分类年份 */
    yearFilter() {
      this.yearList = [];
      let needPush = true;
      for(let i = 0; i < this.listData.length; i++) {
        let tmpYear = this.getYear(this.listData[i]);
        needPush = true;
        for(let j = 0; j < this.yearList.length; j++) {
          if(this.yearList[j].value === tmpYear) {
            needPush = false;
            break;
          }
        }
        if(needPush) {
          this.yearList.push({
            label: tmpYear + '年',
            value: tmpYear
          });
        }
      }
      // 排序
      this.yearList.sort((a, b) => {
        return b - a;
      })
    },
    /** 提取年份 */
    getYear(item) {
      return Number(item.createTime.split('-')[0]);
    },
    /** 获取类型名字 */
    getNameOfType(type) {
      if(type === 'function') {
        return "产品功能"
      } else if(type === 'laf') {
        return "外观感觉"
      } else if(type === 'requirement') {
        return "功效需求"
      } else if(type === 'advise') {
        return "友好建议"
      } else if(type === 'other') {
        return "其他"
      }
    },
    async handleCollapseChange(e) {
      this.detailList = [];
      const res = await apiGetRFIDetail(e);
      if(res.data.code === 200) {
        this.detailList = res.data.rows;
      }
    },
    /** 跳转-表单 */
    gotoImprove() {
      this.$router.push({
        path: "/platforms/improve"
      })
    }
  },
}
</script>

<template>
  <div>
    <main-header :selected="6"></main-header>
    <div class="resolve-page-container">
      <div class="resolve-bg">
        <div class="resolve-bg-text-box">
          <div class="resolve-bg-text">
            查看
          </div>
          <div class="resolve-bg-text-color">
            产品改进建议列表
          </div>
        </div>
      </div>
      <div class="resolve-des-box">
        <div class="resolve-des-title">
          提出问题。获得答案。选择你的DS版本，在下面找到相关的和最常问的问题。如果你找不到你需要的答案，你可以
        </div>
        <div class="resolve-des-item">
          1. 快速提交<span class="resolve-des-item-focus" @click="gotoImprove">表单</span>，对产品提出改进意见
        </div>
        <div class="resolve-des-item">
          2. 加入我们的社群，将您的问题<span class="resolve-des-item-focus" @click="isPopCodeShowDiag = true">与医肤师交流</span>
        </div>
        <div class="resolve-des-item">
          3. 发送邮件给<span class="resolve-des-item-focus">rfi@aromore.com</span>
        </div>
      </div>
      <div class="resolve-items-box">
        <div class="resolve-items-title">
          请求产品改进建议
        </div>
        <div class="resolve-items" v-if="listData.length > 0">
          <el-collapse v-model="activeName" accordion @change="handleCollapseChange">
            <el-collapse-item v-for="(item, index) in listData" :key="index" v-if="getYear(item) === selectYear"
                              :title="item.title" :name="item.id">
              <div>分类：{{ getNameOfType(item.rfiType) }}</div>
              <div>描述：{{ item.description }}</div>
              <div class="resolve-item-detail">跟进记录：</div>
              <div class="resolve-item-wait" v-if="detailList.length === 0">
                等待跟进
              </div>
              <div class="resolve-item-line" v-else>
                <el-timeline :reverse="true">
                  <el-timeline-item
                      v-for="(itemT, indexT) in detailList"
                      :key="indexT"
                      :timestamp="itemT.createTime">
                    {{itemT.comment}}
                  </el-timeline-item>
                </el-timeline>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div v-else>
          <el-empty description="暂无内容，等待您的宝贵意见"></el-empty>
        </div>
      </div>
    </div>
    <div class="resolve-aside-box">
      <div class="resolve-aside-title">
        按年份
      </div>
      <div class="resolve-aside-items">
        <div class="resolve-aside-item" v-for="(item, index) in yearList" :key="index"
             @click="onYearSelect(item.value)">
          <div class="resolve-aside-item-selected" v-if="selectYear === item.value"></div>
          {{ item.label }}
        </div>
      </div>
    </div>
    <el-dialog title="微信扫码开始鉴图" :visible.sync="isPopCodeShowDiag" width="300px" center :show-close="false">
      <div class="pop-code-box">
        <img :src="require('/src/assets/images/code-DIAG.png')" alt class="pop-code-img">
      </div>
    </el-dialog>
    <main-footer></main-footer>
  </div>
</template>

<style scoped lang="scss">
.resolve-page-container {
  width: 100%;
  padding: 102px 0 0;

  .resolve-bg {
    align-items: center;
    background-color: #f5f5f5;
    display: flex;
    height: 176px;
    justify-content: center;

    .resolve-bg-text-box {
      color: #031b3e;
      display: flex;
      font-size: 68px;
      font-weight: 700;
      line-height: 90px;
      width: 1000px;

      .resolve-bg-text {

      }
      .resolve-bg-text-color {
        margin-left: 20px;
        -webkit-background-clip: text;
        background-image: linear-gradient(90deg, #437deb, #5ae3f8);
        color: transparent;
        display: inline-block;
      }
    }
  }
  .resolve-des-box {
    width: 1280px;
    margin: 0 auto;
    color: #000;
    font-size: 18px;
    line-height: 43px;
    padding: 30px 20px 20px;

    .resolve-des-title {
      font-weight: bold;
    }
    .resolve-des-item {

      .resolve-des-item-focus {
        color: #1677ff;
        cursor: pointer;
      }
    }
  }
  .resolve-items-box {
    width: 1280px;
    margin: 0 auto;
    padding-bottom: 40px;

    .resolve-items-title {
      align-items: center;
      border-top: 1px solid #707070;
      display: flex;
      justify-content: space-between;
      padding: 38px 20px;
      color: #031b3e;
      font-size: 48px;
      font-weight: 700;
      line-height: 53px;
    }
    .resolve-items {

      .resolve-item-detail {
        margin-top: 8px;
      }
      .resolve-item-wait {
        color: #aeaeae;
      }
      .resolve-item-line {
        margin-top: 8px;
        margin-left: -30px;
      }
    }
  }
}
.resolve-aside-box {
  position: fixed;
  left: 0;
  top: 150px;
  height: 600px;
  width: 150px;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  padding: 16px;
  box-sizing: border-box;

  .resolve-aside-title {
    font-size: 20px;
    font-weight: bold;
    color: $color-green-deep;
  }
  .resolve-aside-items {
    margin-top: 20px;

    .resolve-aside-item {
      cursor: pointer;
      padding: 6px 0;
      display: flex;
      align-items: center;
      gap: 10px;

      .resolve-aside-item-selected {
        width: 4px;
        height: 16px;
        background-color: $color-green-deep;
      }
    }
  }
}
.pop-code-box {
  display: flex;
  align-items: center;
  justify-content: center;

  .pop-code-img {
    width: 200px;
    height: 200px;
  }
}

// 折叠面板
/deep/.el-collapse {
  border: none;
}
/deep/.el-collapse,.el-collapse-item__wrap {
  border: none;
}
/deep/ .el-collapse-item__header {
  color: #031b3e;
  font-size: 18px;
  font-weight: 700;
  background-color: #f5f5f5;
  padding: 6px 16px;
  border: none;
}
/deep/ .el-collapse-item__content {
  background-color: #fafafa;
  padding: 16px;
  border: none;
}
</style>

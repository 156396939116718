<script>
/* eslint-disable */
export default {
  name: "doctor",
  data() {
    return {
      // 小程序码弹窗
      isPopCodeShow: false
    }
  },
  methods: {
    /** 跳转路径 */
    toPath(url) {
      this.$router.push({
        path: url,
      })
    }
  }
}
</script>

<template>
  <div>
    <main-header :selected="3"></main-header>
    <div class="container">
      <div class="head-img-container">
        <img class="head-img" :src="require('/src/assets/images/ai-doctor/bg-doctor.jpg')" alt>
        <div class="head-btn-box">
          <el-button class="head-button" @click="isPopCodeShow = true">进入测试</el-button>
        </div>
      </div>
    </div>
    <div class="container-body">
      <!-- 大图片组件 -->
      <div class="big-img-box">
        <div class="bi-txt">
          <div class="bi-txt-title">鲍曼医生测试</div>
          <div class="bi-txt-tip">业内公认的皮肤分型测试</div>
        </div>
        <img class="bi-img" :src="require('/src/assets/images/ai-doctor/bowman4.jpg')" alt>
      </div>

      <!-- 左右图文组件 -->
      <div class="img-txt-container-left">
        <div class="bi-txt">
          <div class="bi-txt-title">测试维度</div>
          <div class="bi-txt-tip">皮肤测试的4个维度</div>
        </div>
        <div class="t-2-body">
          <img class="t-2-img" alt :src="require('/src/assets/images/ai-doctor/bowman2.jpg')">
          <div class="t-2-cr-box">
            <div class="t-2-cr">
              <div class="t-2-cr-title">干性 vs 油性</div>
              <div class="t-2-cr-text">
                皮肤表皮层的水油平衡能力，干皮在选择产品时应该加强封闭性保湿，油皮避免致痘风险较高的成分
              </div>
            </div>
            <div class="t-2-cr">
              <div class="t-2-cr-title">敏感 vs 耐受</div>
              <div class="t-2-cr-text">
                皮肤抵抗外界刺激的能力，体现了屏障功能
              </div>
            </div>
            <div class="t-2-cr">
              <div class="t-2-cr-title">色素 vs 非色素</div>
              <div class="t-2-cr-text">
                皮肤色素沉积的程度。皮肤色素由黑色素细胞分泌，通过黑色素体运输到表皮细胞，从而影响皮肤呈色
              </div>
            </div>
            <div class="t-2-cr">
              <div class="t-2-cr-title">紧致 vs 皱纹</div>
              <div class="t-2-cr-text">
                皮肤老化的程度。皮肤老化受内因（年龄、遗传、内分泌等）和外因（紫外线、压力生活习惯等）影响
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 卡片组件 -->
      <div class="card-container">
        <div class="bi-txt">
          <div class="bi-txt-title">皮肤分型</div>
          <div class="bi-txt-tip">根据测试维度，分为16种皮肤类型</div>
        </div>
        <div class="card-box">
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/ai-doctor/card-icon.jpg')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">DRPT</div>
                <p>词条1：xxxx</p>
                <p>词条2：xxxx</p>
                <p>词条3：xxxx</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/ai-doctor/card-icon.jpg')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">DRNT</div>
                <p>词条1：xxxx</p>
                <p>词条2：xxxx</p>
                <p>词条3：xxxx</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/ai-doctor/card-icon.jpg')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">DSPT</div>
                <p>词条1：xxxx</p>
                <p>词条2：xxxx</p>
                <p>词条3：xxxx</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/ai-doctor/card-icon.jpg')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">DSNT</div>
                <p>词条1：xxxx</p>
                <p>词条2：xxxx</p>
                <p>词条3：xxxx</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/ai-doctor/card-icon.jpg')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">DRPW</div>
                <p>词条1：xxxx</p>
                <p>词条2：xxxx</p>
                <p>词条3：xxxx</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/ai-doctor/card-icon.jpg')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">DRNW</div>
                <p>词条1：xxxx</p>
                <p>词条2：xxxx</p>
                <p>词条3：xxxx</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/ai-doctor/card-icon.jpg')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">DSPW</div>
                <p>词条1：xxxx</p>
                <p>词条2：xxxx</p>
                <p>词条3：xxxx</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/ai-doctor/card-icon.jpg')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">DSNW</div>
                <p>词条1：xxxx</p>
                <p>词条2：xxxx</p>
                <p>词条3：xxxx</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/ai-doctor/card-icon.jpg')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">ORPT</div>
                <p>词条1：xxxx</p>
                <p>词条2：xxxx</p>
                <p>词条3：xxxx</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/ai-doctor/card-icon.jpg')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">ORNT</div>
                <p>词条1：xxxx</p>
                <p>词条2：xxxx</p>
                <p>词条3：xxxx</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/ai-doctor/card-icon.jpg')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">OSPT</div>
                <p>词条1：xxxx</p>
                <p>词条2：xxxx</p>
                <p>词条3：xxxx</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/ai-doctor/card-icon.jpg')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">OSNT</div>
                <p>词条1：xxxx</p>
                <p>词条2：xxxx</p>
                <p>词条3：xxxx</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/ai-doctor/card-icon.jpg')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">ORPW</div>
                <p>词条1：xxxx</p>
                <p>词条2：xxxx</p>
                <p>词条3：xxxx</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/ai-doctor/card-icon.jpg')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">ORNW</div>
                <p>词条1：xxxx</p>
                <p>词条2：xxxx</p>
                <p>词条3：xxxx</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/ai-doctor/card-icon.jpg')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">OSPW</div>
                <p>词条1：xxxx</p>
                <p>词条2：xxxx</p>
                <p>词条3：xxxx</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/ai-doctor/card-icon.jpg')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">OSNW</div>
                <p>词条1：xxxx</p>
                <p>词条2：xxxx</p>
                <p>词条3：xxxx</p>
              </div>
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <el-dialog title="微信扫码开始测试" :visible.sync="isPopCodeShow" width="300px" center :show-close="false">
      <div class="pop-code-box">
        <img :src="require('/src/assets/images/code-BOWMAN.jpg')" alt class="pop-code-img">
      </div>
    </el-dialog>
    <main-footer></main-footer>
  </div>
</template>

<style scoped lang="scss">
@import "~@/styles/tested/doctor";
</style>

/** ftp服务器路径 */
const ftp = "http://res.aromore.com/static/";

export default {
    INDEX: {
        logo: ftp + 'index/footer_logo_hd.png',
        logo_footer: ftp + 'index/footer_logo_hd_gray.png',
        card_1: ftp + 'index/index_card_1.png',
        card_2: ftp + 'index/index_card_2.png',
        card_3: ftp + 'index/index_card_3.png',
        card_4: ftp + 'index/index_card_4.png',
        code_gongzhonghao: ftp + 'index/code_gongzhonghao.jpg',
        code_kefu: ftp + 'index/code_kefu.png',
        avatar_example: ftp + 'index/example.png',
        calendar: ftp + 'index/calendar.png',
        share: ftp + 'index/share.png',
        fea_1: ftp + 'index/fea-1.png',
        fea_2: ftp + 'index/fea-2.png',
        fea_3: ftp + 'index/fea-3.png',
        fea_4: ftp + 'index/fea-4.png',
    },
    MEASURE: {
        pic_1: ftp + 'measure/pic-1.png',
        pic_2: ftp + 'measure/pic-2.png',
        phone: ftp + 'measure/phone.png',
        photo_1: ftp + 'measure/photo1.png',
        photo_2: ftp + 'measure/photo2.png',
        photo_3: ftp + 'measure/photo3.png',
        photo_4: ftp + 'measure/photo4.png',
        camera_face: ftp + 'measure/camera_face.png',
        bowman_header: ftp + 'measure/bowman_header.png'
    },
    DIAGNOSIS: {
        diag_1: ftp + 'diagnosis/diag_1.png',
        diag_2: ftp + 'diagnosis/diag_2.png',
        diag_3: ftp + 'diagnosis/diag_3.png',
        diag_4: ftp + 'diagnosis/diag_4.png',
        head_bg1: ftp + 'diagnosis/header_bg_upload_triage_photos.jpg',
        head_bg2: ftp + 'diagnosis/header_bg_upload_guide.jpg',
        guide_bg: ftp + 'diagnosis/lights_guide.png',
        guide_1: ftp + 'diagnosis/image_guide_one.jpg',
        guide_2: ftp + 'diagnosis/image_guide_two.jpg',
        guide_3: ftp + 'diagnosis/image_guide_three.jpg',
        guide_4: ftp + 'diagnosis/image_guide_four.jpg',
        guide_5: ftp + 'diagnosis/image_guide_five.jpg',
        guide_6: ftp + 'diagnosis/image_guide_six.jpg',
        sample_1: ftp + 'diagnosis/sample_1.jpg',
        sample_2: ftp + 'diagnosis/sample_2.jpg',
        sample_3: ftp + 'diagnosis/sample_3.jpg',
        sample_4: ftp + 'diagnosis/sample_4.jpg',
        sample_5: ftp + 'diagnosis/sample_5.jpg',
        sample_6: ftp + 'diagnosis/sample_6.jpg'
    },
    MALL: {
        cate_all: ftp + 'mall/cate_all.png',
        del: ftp + 'mall/del.png',
        mall_1: ftp + 'mall/mall-1.png',
        mall_2: ftp + 'mall/mall-2.png',
        mall_3: ftp + 'mall/mall-3.png',
    },
    MODEL: {
        icon_5: ftp + 'model/icon-5.png',
        icon_6: ftp + 'model/icon-6.png',
        icon_7: ftp + 'model/icon-7.png',
        icon_8: ftp + 'model/icon-8.png',
        icon_9: ftp + 'model/icon-9.png',
        icon_10: ftp + 'model/icon-10.png',
        icon_11: ftp + 'model/icon-11.png',
        icon_12: ftp + 'model/icon-12.png',
        pic_3: ftp + 'model/pic-3.png',
        book_guide_0: ftp + 'model/book-guide-0.png',
        book_guide_1_1: ftp + 'model/book-guide-1-1.png',
        book_guide_1_2: ftp + 'model/book-guide-1-2.png',
        book_guide_2_1: ftp + 'model/book-guide-2-1.png',
        book_guide_2_2: ftp + 'model/book-guide-2-2.png',
        book_guide_3_1: ftp + 'model/book-guide-3-1.png',
        book_guide_3_2: ftp + 'model/book-guide-3-2.png',
        book_guide_4_1: ftp + 'model/book-guide-4-1.png',
        book_guide_4_2: ftp + 'model/book-guide-4-2.png',
    },
    PROPOSAL: {
        icon_1: ftp + 'proposal/icon-1.png',
        icon_2: ftp + 'proposal/icon-2.png',
        icon_3: ftp + 'proposal/icon-3.png',
        icon_4: ftp + 'proposal/icon-4.png'
    }
}

<script>
/* eslint-disable */
export default {
  name: "archives",
  data() {
    return {
      /**
       * 测试内容表单数组
       * 8道问题，编号为0-7
       */
      questionInfo: [
        {
          id: 0,
          choice: null,
          options: [
            {
              text: "20岁以下",
              imgURL: require("/src/assets/images/tested/1-1.jpg")
            },
            {
              text: "20-29岁",
              imgURL: require("/src/assets/images/tested/1-2.jpg")
            },
            {
              text: "30-39岁",
              imgURL: require("/src/assets/images/tested/1-3.jpg")
            },
            {
              text: "40-49岁",
              imgURL: require("/src/assets/images/tested/1-4.jpg")
            },
            {
              text: "50-59岁",
              imgURL: require("/src/assets/images/tested/1-5.jpg")
            },
            {
              text: "60岁以上",
              imgURL: require("/src/assets/images/tested/1-6.jpg")
            },
          ]
        },
        {
          id: 1,
          choice: null,
          options: [
            {
              text: "干燥·粗糙",
              imgURL: require("/src/assets/images/tested/2-1.jpg")
            },
            {
              text: "小皱纹",
              imgURL: require("/src/assets/images/tested/2-2.jpg")
            },
            {
              text: "色斑",
              imgURL: require("/src/assets/images/tested/2-3.jpg")
            },
            {
              text: "毛孔粗大·粉刺",
              imgURL: require("/src/assets/images/tested/2-4.jpg")
            },
            {
              text: "晦暗·暗黄",
              imgURL: require("/src/assets/images/tested/2-5.jpg")
            },
          ]
        },
        {
          id: 2,
          choice: null,
          options: [
            {
              text: "黑眼圈",
              imgURL: require("/src/assets/images/tested/3-1.jpg")
            },
            {
              text: "眼周皱纹",
              imgURL: require("/src/assets/images/tested/3-2.jpg")
            },
            {
              text: "眼袋",
              imgURL: require("/src/assets/images/tested/3-3.jpg")
            },
            {
              text: "浮肿",
              imgURL: require("/src/assets/images/tested/3-4.jpg")
            },
            {
              text: "完美无瑕",
              imgURL: require("/src/assets/images/tested/3-5.jpg")
            },
          ]
        },
        {
          id: 3,
          choice: null,
          options: [
            {
              text: "极缺水分干涩肌",
              imgURL: require("/src/assets/images/tested/4-1.jpg")
            },
            {
              text: "时干时润易涩肌",
              imgURL: require("/src/assets/images/tested/4-2.jpg")
            },
            {
              text: "水分充足水润肌",
              imgURL: require("/src/assets/images/tested/4-3.jpg")
            },
          ]
        },
        {
          id: 4,
          choice: null,
          options: [
            {
              text: "油腻",
              imgURL: require("/src/assets/images/tested/5-1.jpg")
            },
            {
              text: "无油光",
              imgURL: require("/src/assets/images/tested/5-2.jpg")
            },
            {
              text: "干燥起皮",
              imgURL: require("/src/assets/images/tested/5-3.jpg")
            },
          ]
        },
        {
          id: 5,
          choice: null,
          options: [
            {
              text: "偏红肌",
              imgURL: require("/src/assets/images/tested/6-1.jpg")
            },
            {
              text: "明亮肌",
              imgURL: require("/src/assets/images/tested/6-2.jpg")
            },
            {
              text: "自然肌",
              imgURL: require("/src/assets/images/tested/6-3.jpg")
            },
            {
              text: "小麦肌",
              imgURL: require("/src/assets/images/tested/6-4.jpg")
            },
          ]
        },
        {
          id: 6,
          choice: null,
          options: [
            {
              text: "劲爆辣妈",
              imgURL: require("/src/assets/images/tested/7-1.jpg")
            },
            {
              text: "旅游达人",
              imgURL: require("/src/assets/images/tested/7-2.jpg")
            },
            {
              text: "优雅白领",
              imgURL: require("/src/assets/images/tested/7-3.jpg")
            },
            {
              text: "运动健将",
              imgURL: require("/src/assets/images/tested/7-4.jpg")
            },
            {
              text: "温室花朵",
              imgURL: require("/src/assets/images/tested/7-5.jpg")
            },
          ]
        },
        {
          id: 7,
          choice: null,
          options: [
            {
              text: "柔和印象",
              imgURL: require("/src/assets/images/tested/8-1.jpg")
            },
            {
              text: "鲜明印象",
              imgURL: require("/src/assets/images/tested/8-2.jpg")
            },
          ]
        },
      ],
      // 当前步骤可视数组
      stepHeightList: [true,true,false,false,false,false,false,false,false,false,false],
      step: 0,
      stepView: 0,
      loading: false,
    }
  },
  created() {
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    // 滚动按钮
    onUpClick(upId) {
      document.getElementById(upId).scrollIntoView({behavior:"smooth"})
    },
    onDownClick(downId) {
      document.getElementById(downId).scrollIntoView({behavior:"smooth"})
    },
    // 禁用滚轮
    handleScroll(event) {
      event.preventDefault();
    },
    // 选择按钮
    onButtonClick(question, option) {
      this.questionInfo[question].choice = option
      this.stepHeightList[question + 2] = true
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.onDownClick("index-" + (question + 2).toString())
      }, 500)
    },
    // 重置
    resetQuestion() {
      for(let i = 0; i < this.questionInfo.length; i++) {
        this.questionInfo[i].choice = null
      }
      this.onUpClick("index-1")
      for(let i = 2; i < this.stepHeightList.length; i++) {
        this.stepHeightList[i] = false
      }
    },
    // 确认
    confirmQuestion() {
      this.stepHeightList[10] = true
      this.loading = true
      setTimeout(() => {
        this.loading = false
        this.onDownClick("index-10")
      }, 500)
    },
    // 提交
    onSubmit(type) {
      this.$router.push({
        path: "/diagnosis/archives/result"
      })
    }
  },
}
</script>

<template>
  <div class="root" v-loading="loading">
    <main-header :selected="4" class="header"></main-header>
    <div class="q-items">
      <div class="container" id="index-0">
        <div class="q-canvas q-color">
          <div class="i0-box">
            <div class="i0-title">在线医肤档案</div>
            <div>
              <div class="i0-theme">A R O M O R E</div>
              <div class="i0-theme-cn">阿 茉 尔</div>
            </div>
            <div class="i0-button" @click="onDownClick('index-1')">
              <div class="i0-title">开始测试</div>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="container" id="index-1" v-show="true">
        <div class="q-canvas q-white">
          <div class="i1-box">
            <div class="i1-button" @click="onUpClick('index-0')">
              <i class="el-icon-arrow-up"></i>
              <div class="i1-title">返回上一级</div>
            </div>
            <div class="i1-q-box">
              <div class="i1-q-title">请问您的年龄是？</div>
              <div class="i1-q">
                <div class="i1-q-item" @click="onButtonClick(0,0)">
                  <img class="i1-q-img" :src="questionInfo[0].options[0].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[0].choice" :label="0" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[0].options[0].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(0,1)">
                  <img class="i1-q-img" :src="questionInfo[0].options[1].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[0].choice" :label="1" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[0].options[1].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(0,2)">
                  <img class="i1-q-img" :src="questionInfo[0].options[2].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[0].choice" :label="2" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[0].options[2].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(0,3)">
                  <img class="i1-q-img" :src="questionInfo[0].options[3].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[0].choice" :label="3" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[0].options[3].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(0,4)">
                  <img class="i1-q-img" :src="questionInfo[0].options[4].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[0].choice" :label="4" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[0].options[4].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(0,5)">
                  <img class="i1-q-img" :src="questionInfo[0].options[5].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[0].choice" :label="5" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[0].options[5].text}}</span>
                    </el-radio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container" id="index-2" v-show="stepHeightList[2]">
        <div class="q-canvas q-color">
          <div class="i1-box">
            <div class="i1-button" @click="onUpClick('index-1')">
              <i class="el-icon-arrow-up"></i>
              <div class="i1-title">返回上一级</div>
            </div>
            <div class="i1-q-box">
              <div class="i1-q-title">最困扰您的肌肤问题是什么？</div>
              <div class="i1-q">
                <div class="i1-q-item" @click="onButtonClick(1,0)">
                  <img class="i1-q-img" :src="questionInfo[1].options[0].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[1].choice" :label="0" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[1].options[0].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(1,1)">
                  <img class="i1-q-img" :src="questionInfo[1].options[1].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[1].choice" :label="1" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[1].options[1].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(1,2)">
                  <img class="i1-q-img" :src="questionInfo[1].options[2].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[1].choice" :label="2" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[1].options[2].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(1,3)">
                  <img class="i1-q-img" :src="questionInfo[1].options[3].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[1].choice" :label="3" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[1].options[3].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(1,4)">
                  <img class="i1-q-img" :src="questionInfo[1].options[4].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[1].choice" :label="4" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[1].options[4].text}}</span>
                    </el-radio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container" id="index-3" v-show="stepHeightList[3]">
        <div class="q-canvas q-white">
          <div class="i1-box">
            <div class="i1-button" @click="onUpClick('index-2')">
              <i class="el-icon-arrow-up"></i>
              <div class="i1-title">返回上一级</div>
            </div>
            <div class="i1-q-box">
              <div class="i1-q-title">最困扰您的肌肤问题是什么？</div>
              <div class="i1-q">
                <div class="i1-q-item" @click="onButtonClick(2,0)">
                  <img class="i1-q-img" :src="questionInfo[2].options[0].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[2].choice" :label="0" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[2].options[0].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(2,1)">
                  <img class="i1-q-img" :src="questionInfo[2].options[1].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[2].choice" :label="1" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[2].options[1].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(2,2)">
                  <img class="i1-q-img" :src="questionInfo[2].options[2].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[2].choice" :label="2" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[2].options[2].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(2,3)">
                  <img class="i1-q-img" :src="questionInfo[2].options[3].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[2].choice" :label="3" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[2].options[3].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(2,4)">
                  <img class="i1-q-img" :src="questionInfo[2].options[4].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[2].choice" :label="4" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[2].options[4].text}}</span>
                    </el-radio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container" id="index-4" v-show="stepHeightList[4]">
        <div class="q-canvas q-color">
          <div class="i1-box">
            <div class="i1-button" @click="onUpClick('index-3')">
              <i class="el-icon-arrow-up"></i>
              <div class="i1-title">返回上一级</div>
            </div>
            <div class="i1-q-box">
              <div class="i1-q-title">最近，洗完脸后不涂抹任何化妆品的话？</div>
              <div class="i1-q">
                <div class="i1-q-item" @click="onButtonClick(3,0)">
                  <img class="i1-q-img" :src="questionInfo[3].options[0].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[3].choice" :label="0" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[3].options[0].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(3,1)">
                  <img class="i1-q-img" :src="questionInfo[3].options[1].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[3].choice" :label="1" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[3].options[1].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(3,2)">
                  <img class="i1-q-img" :src="questionInfo[3].options[2].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[3].choice" :label="2" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[3].options[2].text}}</span>
                    </el-radio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container" id="index-5" v-show="stepHeightList[5]">
        <div class="q-canvas q-white">
          <div class="i1-box">
            <div class="i1-button" @click="onUpClick('index-4')">
              <i class="el-icon-arrow-up"></i>
              <div class="i1-title">返回上一级</div>
            </div>
            <div class="i1-q-box">
              <div class="i1-q-title">最近，会经常感觉到额头鼻子？</div>
              <div class="i1-q">
                <div class="i1-q-item" @click="onButtonClick(4,0)">
                  <img class="i1-q-img" :src="questionInfo[4].options[0].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[4].choice" :label="0" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[4].options[0].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(4,1)">
                  <img class="i1-q-img" :src="questionInfo[4].options[1].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[4].choice" :label="1" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[4].options[1].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(4,2)">
                  <img class="i1-q-img" :src="questionInfo[4].options[2].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[4].choice" :label="2" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[4].options[2].text}}</span>
                    </el-radio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container" id="index-6" v-show="stepHeightList[6]">
        <div class="q-canvas q-color">
          <div class="i1-box">
            <div class="i1-button" @click="onUpClick('index-5')">
              <i class="el-icon-arrow-up"></i>
              <div class="i1-title">返回上一级</div>
            </div>
            <div class="i1-q-box">
              <div class="i1-q-title">素颜时您的肤色情况是什么？</div>
              <div class="i1-q">
                <div class="i1-q-item" @click="onButtonClick(5,0)">
                  <img class="i1-q-img" :src="questionInfo[5].options[0].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[5].choice" :label="0" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[5].options[0].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(5,1)">
                  <img class="i1-q-img" :src="questionInfo[5].options[1].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[5].choice" :label="1" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[5].options[1].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(5,2)">
                  <img class="i1-q-img" :src="questionInfo[5].options[2].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[5].choice" :label="2" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[5].options[2].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(5,3)">
                  <img class="i1-q-img" :src="questionInfo[5].options[3].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[5].choice" :label="3" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[5].options[3].text}}</span>
                    </el-radio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container" id="index-7" v-show="stepHeightList[7]">
        <div class="q-canvas q-white">
          <div class="i1-box">
            <div class="i1-button" @click="onUpClick('index-6')">
              <i class="el-icon-arrow-up"></i>
              <div class="i1-title">返回上一级</div>
            </div>
            <div class="i1-q-box">
              <div class="i1-q-title">您是？</div>
              <div class="i1-q">
                <div class="i1-q-item" @click="onButtonClick(6,0)">
                  <img class="i1-q-img" :src="questionInfo[6].options[0].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[6].choice" :label="0" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[6].options[0].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(6,1)">
                  <img class="i1-q-img" :src="questionInfo[6].options[1].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[6].choice" :label="1" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[6].options[1].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(6,2)">
                  <img class="i1-q-img" :src="questionInfo[6].options[2].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[6].choice" :label="2" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[6].options[2].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(6,3)">
                  <img class="i1-q-img" :src="questionInfo[6].options[3].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[6].choice" :label="3" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[6].options[3].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(6,4)">
                  <img class="i1-q-img" :src="questionInfo[6].options[4].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[6].choice" :label="4" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[6].options[4].text}}</span>
                    </el-radio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container" id="index-8" v-show="stepHeightList[8]">
        <div class="q-canvas q-color">
          <div class="i1-box">
            <div class="i1-button" @click="onUpClick('index-7')">
              <i class="el-icon-arrow-up"></i>
              <div class="i1-title">返回上一级</div>
            </div>
            <div class="i1-q-box">
              <div class="i1-q-title">您希望呈现的彩妆效果是？</div>
              <div class="i1-q">
                <div class="i1-q-item" @click="onButtonClick(7,0)">
                  <img class="i1-q-img" :src="questionInfo[7].options[0].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[7].choice" :label="0" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[7].options[0].text}}</span>
                    </el-radio>
                  </div>
                </div>
                <div class="i1-q-item" @click="onButtonClick(7,1)">
                  <img class="i1-q-img" :src="questionInfo[7].options[1].imgURL" alt>
                  <div class="i1-q-button-box">
                    <el-radio v-model="questionInfo[7].choice" :label="1" class="i1-radio">
                      <span class="i1-button-text">{{questionInfo[7].options[1].text}}</span>
                    </el-radio>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container" id="index-9" v-if="stepHeightList[9]">
        <div class="q-canvas q-white">
          <div class="i1-box">
            <div class="i1-button" @click="onUpClick('index-8')">
              <i class="el-icon-arrow-up"></i>
              <div class="i1-title">返回上一级</div>
            </div>
            <div class="i1-q-box">
              <div class="i9-q-title">为了更好地甄选出适合您的产品<br>
                请确认您的信息</div>
              <div class="i9-q">
                <div class="i1-q-item" v-for="item in questionInfo" :key="item.id">
                  <img class="i1-q-img" :src="item.options[item.choice].imgURL" alt>
                  <div class="i1-q-button-box">
                    <span class="i1-button-text">{{item.options[item.choice].text}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="i9-button-box">
              <el-button class="i9-button" @click="resetQuestion">重新测试</el-button>
              <el-button class="i9-button" @click="confirmQuestion">全部确认</el-button>
            </div>
          </div>
        </div>
      </div>

      <div class="container" id="index-10" v-show="stepHeightList[10]">
        <div class="q-canvas q-white">
          <div class="i1-box">
            <div class="i1-button" @click="onUpClick('index-9')">
              <i class="el-icon-arrow-up"></i>
              <div class="i1-title">返回上一级</div>
            </div>
            <div class="i1-q-box">
              <div class="i9-q-title">
                您已完成测试<br>
                点击查看适合您的护理方案<br>
                帮您实现 完美肌肤
              </div>
              <div class="i10-button-box">
                <div>
                  <el-button class="i9-button i10-button" @click="onSubmit(0)">美肤护理</el-button>
                </div>
                <div>
                  <el-button class="i9-button i10-button" @click="onSubmit(1)">修肤护理</el-button>
                </div>
                <div>
                  <el-button class="i9-button i10-button" @click="onSubmit(2)">育肤护理</el-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <main-footer v-show="stepHeightList[10]"></main-footer>
  </div>
</template>

<style scoped lang="scss">
@import "~@/styles/tested/index";
</style>

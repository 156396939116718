<script>
/* eslint-disable */
export default {
  name: "remote",
  data() {
    return {
      bicSelect: 1,
      // 扫码
      isPopCodeShow: false,
      isPopCodeShowDiag: false,
    }
  },
  methods: {
    /** 跳转路径 */
  }
}
</script>

<template>
  <div>
    <main-header :selected="4"></main-header>
    <div class="container">
      <div class="head-img-container">
        <img class="head-img" :src="require('/src/assets/images/diagnosis/remote/bg.jpg')" alt>
        <div class="head-content-box">
          <div class="head-txt-box">
            <div class="head-txt-title">专家人工导诊</div>
            <div class="head-txt-content">解决用户盲目就诊问题，一对一的专业医肤师咨询服务。用户只需通过图文信息，就能获得对自己肌肤问题的深入分析和专业的治疗建议。</div>
            <div class="head-btn-box">
              <el-button class="head-button" @click="isPopCodeShowDiag = true">立即预约医肤师</el-button>
            </div>
          </div>
          <img class="head-img-m" :src="require('/src/assets/images/diagnosis/remote/bg-m.png')" alt>
        </div>
      </div>
    </div>
    <div class="container-body">

      <!-- 大卡片组件 -->
      <div class="bic-container">
        <div class="bi-txt">
          <div class="bi-txt-title">专家服务</div>
          <!--          <div class="bi-txt-tip">示例标题示例标题</div>-->
        </div>
        <div class="bic-card">
          <div class="bic-main">
            <div class="bic-main-item" v-show="bicSelect === 1">
              <img class="bic-img" :src="require('/src/assets/images/diagnosis/remote/se-1.jpg')" alt>
              <div class="bic-main-text-box">
                <div class="bic-m-t-title-box">
                  <div class="bic-m-t-line"></div>
                  <div class="bic-m-t-title">远程诊断</div>
                </div>
                <div class="bic-m-t-text-box">
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">获得专业医肤师一对一的咨询服务,提高诊疗准确性</div>
                  </div>
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">获得对自己肌肤问题的深入分析和专业的治疗建议</div>
                  </div>
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">不再需要亲自前往诊所或美容院，就能获得专业的护肤指导</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bic-main-item" v-show="bicSelect === 2">
              <img class="bic-img" :src="require('/src/assets/images/diagnosis/remote/se-2.jpg')" alt>
              <div class="bic-main-text-box">
                <div class="bic-m-t-title-box">
                  <div class="bic-m-t-line"></div>
                  <div class="bic-m-t-title">配方定制</div>
                </div>
                <div class="bic-m-t-text-box">
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">依托自有工厂和首席配方科学家的专利自研配方</div>
                  </div>
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">定制专属的护肤配方和产品组合,特别适合敏感肌</div>
                  </div>
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">诊疗大数据系统,预防可能出现的皮肤问题</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bic-main-item" v-show="bicSelect === 3">
              <img class="bic-img" :src="require('/src/assets/images/diagnosis/remote/se-4.jpg')" alt>
              <div class="bic-main-text-box">
                <div class="bic-m-t-title-box">
                  <div class="bic-m-t-line"></div>
                  <div class="bic-m-t-title">科学护肤</div>
                </div>
                <div class="bic-m-t-text-box">
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">结合了气候差异与地理位置差异对肌肤的影响,根据时空模型调整了配方组合</div>
                  </div>

                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">为用户提供了适合其肤质、当前气候与当地环境的产品组合，确保用户无论身处何地，都能够得到最佳的护肤效果</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bic-main-item" v-show="bicSelect === 4">
              <img class="bic-img" :src="require('/src/assets/images/diagnosis/remote/se-3.jpg')" alt>
              <div class="bic-main-text-box">
                <div class="bic-m-t-title-box">
                  <div class="bic-m-t-line"></div>
                  <div class="bic-m-t-title">医肤档案</div>
                </div>
                <div class="bic-m-t-text-box">
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">长期跟踪用户的肌肤变化，记录肌肤状况的波动和季节性变化，以及对不同护肤产品的反应，从而更好地理解用户的肌肤需求</div>
                  </div>
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">为用户定制专属的护肤方案，包括适合的护肤产品配方、使用顺序和频率等，以达到最佳的护肤效果</div>
                  </div>
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">所有护肤建议和产品推荐都基于“医肤档案”中的数据分析，确保决策的科学性和准确性</div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="bic-aside">
            <div class="bic-button" @click="bicSelect = 1" :class="{'bic-button-selected': bicSelect === 1}">
              <p class="bic-button-txt">远程诊断</p>
            </div>
            <div class="bic-button" @click="bicSelect = 2" :class="{'bic-button-selected': bicSelect === 2}">
              <p class="bic-button-txt">配方定制</p>
            </div>
            <div class="bic-button" @click="bicSelect = 3" :class="{'bic-button-selected': bicSelect === 3}">
              <p class="bic-button-txt">科学护肤</p>
            </div>
            <div class="bic-button" @click="bicSelect = 4" :class="{'bic-button-selected': bicSelect === 4}">
              <p class="bic-button-txt">医肤档案</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 大图片组件 -->
<!--      <div class="big-img-box">-->
<!--        <div class="bi-txt">-->
<!--          <div class="bi-txt-title">示例标题</div>-->
<!--          <div class="bi-txt-tip">示例标题示例标题</div>-->
<!--        </div>-->
<!--        <img class="bi-img" :src="require('/src/assets/images/ai-doctor/big-img-2.jpg')" alt>-->
<!--      </div>-->

      <!-- 左右图文组件 -->
      <div class="img-txt-container-left">
        <div class="bi-txt">
          <div class="bi-txt-title">专属配方</div>
<!--          <div class="bi-txt-tip">示例标题示例标题</div>-->
        </div>
        <div class="t-2-body">
          <img class="t-2-img" alt :src="require('/src/assets/images/diagnosis/remote/imt-1.jpg')">
          <div class="t-2-cr-box">
<!--            <div class="t-2-cr">-->
<!--              <div class="t-2-cr-title">示例标题</div>-->
<!--              <div class="t-2-cr-text">-->
<!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
<!--              </div>-->
<!--            </div>-->
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">痘印和痘坑的预防</div>
              </div>
              <div class="t-2-txt-content">温和而有效的配方，减少痘痘治疗过程中可能留下的痘印和痘坑。通过促进肌肤的自然修复过程，减少炎症后的色素沉积，并保护皮肤组织，避免因痘痘炎症严重而导致的损伤。</div>
              <div class="head-btn-box">
                <el-button class="head-button" @click="isPopCodeShow = true">了解详情</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="t-2-body t-2-body-justify">
          <div class="t-2-cr-box">
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">敏感肌肤的特殊护理</div>
              </div>
              <div class="t-2-txt-content">自研配方采用包裹水杨酸，可以减少皮肤的刺激和过敏反应, 为用户提供有效又安全的护肤体验，让敏感肌也能得到妥善的护理。</div>
              <div class="head-btn-box">
                <el-button class="head-button" @click="isPopCodeShow = true">了解详情</el-button>
              </div>
            </div>
          </div>
          <img class="t-2-img" alt :src="require('/src/assets/images/diagnosis/remote/imt-2.jpg')">
        </div>
        <div class="t-2-body t-2-body-justify">
          <img class="t-2-img" alt :src="require('/src/assets/images/diagnosis/remote/imt-3.jpg')">
          <div class="t-2-cr-box">
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">保护皮肤屏障功能</div>
              </div>
              <div class="t-2-txt-content">我们的专利配方原理是促进皮肤角质层软化，而非破坏角质层的方式来促进皮肤更新，这样可以避免速效产品可能导致的皮肤组织变薄和弹性降低的问题。产品使用周期为7天，这是一个科学的周期，既能确保产品的有效性，又能保护皮肤屏障的完整性。</div>
              <div class="head-btn-box">
                <el-button class="head-button" @click="isPopCodeShow = true">了解详情</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="t-2-body t-2-body-justify">
          <div class="t-2-cr-box">
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">季节性肌肤问题</div>
              </div>
              <div class="t-2-txt-content">用户在季节变化期间增加或减少某些护肤步骤，调整护肤品的使用频率，以及提醒用户注意防晒和保湿等。通过这些细致入微的护理方案，帮助用户平稳过渡季节更替，减少环境变化对肌肤的影响</div>
              <div class="head-btn-box">
                <el-button class="head-button" @click="isPopCodeShow = true">了解详情</el-button>
              </div>
            </div>
          </div>
          <img class="t-2-img" alt :src="require('/src/assets/images/diagnosis/remote/imt-4.jpg')">
        </div>
      </div>

<!--      <div class="img-txt-container-left">-->
<!--        <div class="t-2-body">-->
<!--          <div class="t-2-cr-box">-->
<!--            <div class="t-2-cr">-->
<!--              <div class="t-2-cr-title">示例标题</div>-->
<!--              <div class="t-2-cr-text">-->
<!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="t-2-cr">-->
<!--              <div class="t-2-cr-title">示例标题</div>-->
<!--              <div class="t-2-cr-text">-->
<!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="t-2-cr">-->
<!--              <div class="t-2-cr-title">示例标题</div>-->
<!--              <div class="t-2-cr-text">-->
<!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="t-2-cr">-->
<!--              <div class="t-2-cr-title">示例标题</div>-->
<!--              <div class="t-2-cr-text">-->
<!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--          <img class="t-2-img" alt :src="require('/src/assets/images/diagnosis/remote/imt-2.jpg')">-->
<!--        </div>-->
<!--      </div>-->

      <!-- 卡片组件 -->
      <div class="card-container">
        <div class="bi-txt">
          <div class="bi-txt-title">产品优势</div>
<!--          <div class="bi-txt-tip">示例标题示例标题</div>-->
        </div>
        <div class="card-box">
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/diagnosis/remote/cr-1.png')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">个性化专业诊断</div>
                <p>提供个性化的远程诊断，由专业医肤师根据用户的具体肌肤状况提供专业分析和治疗建议。一对一的服务确保了护肤方案的精确性和有效性。</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/diagnosis/remote/cr-2.png')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">定制化护肤方案</div>
                <p>提供专属的配方调整和产品组合。这种定制化的服务不仅提高了治疗效果</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/diagnosis/remote/cr-3.png')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">科学护肤理念</div>
                <p>结合国际肤质分级理论和对气候及地理位置差异的研究，为用户提供适应不同时空背景下的科学护肤方案，提供更为精准的护肤效果。</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/diagnosis/remote/cr-4.png')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">持续肌肤健康管理</div>
                <p>持续跟踪用户的肌肤变化，记录肌肤状况的波动，以及对不同护肤产品的反应。及时调整护肤策略，实现肌肤健康的长期管理。</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/diagnosis/remote/cr-5.png')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">数据驱动配方升级</div>
                <p>根据大数据与人工智能算法，结合我们的诊疗大数据，依托自有实验室以及工厂进行产品迭代，为用户提供更个性化、更有效的护肤产品</p>
              </div>
            </div>
          </el-card>
        </div>
      </div>

    </div>
    <el-dialog title="微信扫码咨询客服" :visible.sync="isPopCodeShow" width="300px" center :show-close="false">
      <div class="pop-code-box">
        <img :src="require('/src/assets/images/code-consult.png')" alt class="pop-code-img">
      </div>
    </el-dialog>
    <el-dialog title="微信扫码开始鉴图" :visible.sync="isPopCodeShowDiag" width="300px" center :show-close="false">
      <div class="pop-code-box">
        <img :src="require('/src/assets/images/code-DIAG.png')" alt class="pop-code-img">
      </div>
    </el-dialog>
    <main-footer></main-footer>
  </div>
</template>

<style scoped lang="scss">
@import "~@/styles/diagnosis/remote";
</style>

<script>
/* eslint-disable */
export default {
  name: "intro"
}
</script>

<template>
  <div>
    <main-header :selected="2"></main-header>

    <div class="container">
      <div class="long-img-box">
        <div class="long-img-title">
          系列介绍
        </div>
      </div>
      <div class="text1-box">
        <div class="text1-text-box">
          <div class="text1-title">专业祛痘</div>
          <div class="text1-text">
            <p>
              &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;自2015年创立以来，我们的专业祛痘品牌一直站在皮肤科学与美容技术融合的前沿。我们深知痘痘问题的复杂性，因此投入大量资源深入研究其成因，并紧跟皮肤科学领域的最新发展。我们的目标是通过创新的护肤产品，为消费者提供全面、有效的痘痘解决方案。
              我们的核心产品线全面覆盖了护肤的每个步骤，从深层清洁的洁面产品、舒缓修复的精华，到滋润保护的面霜，每一款都是针对痘痘肌肤的特殊需求精心设计。我们采用科学严谨的配方，结合精准有效的成分，如白柳树皮提取物、水杨酸等，旨在减少减少对皮肤的刺激的同时，有效治疗和预防痘痘。
              此外，我们还提供个性化的咨询服务，帮助每位消费者找到最适合自己的护肤方案。我们相信，通过我们的专业产品和贴心服务，可以帮助每一位消费者重拾肌肤的自然健康与美丽。我们承诺，将持续推动产品创新，不断提升服务质量，以满足消费者日益增长的护肤需求。 </p>
          </div>
        </div>
        <div class="text1-img-box">
          <img class="text1-img" alt :src="require('@/assets/images/acne/intro-1.jpg')">
        </div>
      </div>
      <div class="text1-box about-end-margin">
        <div class="text1-img-box">
          <img class="develop-img" alt :src="require('@/assets/images/acne/intro-2.jpg')">
        </div>
        <div class="text1-text-box">
          <div class="text1-title">医研共创</div>
          <div class="text1-text">
            <p>
              &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;秉承医研共创的精神，我们专注于将皮肤科医学的专业理论与护肤品研发的先进技术相结合。这一过程中，我们深入研究痘痘的成因，精心挑选有效成分，如白柳树皮提取物和水杨酸，并通过科学配方，打造出既能深入治疗痘痘、又能温和护理肌肤的全系列产品。
              此外，我们还提供个性化的肌肤咨询，帮助每位消费者找到最适合自己的护肤策略。我们坚持持续创新，不断优化产品配方，以适应不断变化的市场需求。我们的目标是通过专业、科学的护肤方案，帮助用户恢复肌肤健康，重拾自信。
            </p>
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<style scoped>
@import "~@/styles/acne/intro.scss";
</style>

<script>
/* eslint-disable */
import {apiGoodsDetail} from "@/common/api";

export default {
  name: "detail",
  data() {
    return {
      // 产品详情数组（模拟获取后端接口）
      productData: [
        {
          id: 0,
        },
        {
          id: 1,
        },
        {
          id: 2,
          name: "example-2",
          label: "双重保湿水感防晒露SPF50",
          tip: "防晒黑，防晒干，清透薄",
          price: 109.90,
          series: 3,
          demand: 1,

          imgURL: require('@/assets/images/product/detail/example-x.jpg'),
          unit: "80g",
          imgIntroURL: [
            require('@/assets/images/product/detail/example-x-1.jpg'),
            require('@/assets/images/product/detail/example-x-2.jpg'),
          ],
          introText: [
            "阿茉尔Solarex-5专研防晒技术",
            "稳定，广谱，呵护，保湿，舒缓",
            "高倍防晒，防晒黑防晒干防晒老",
            "滋润力提升93%，一抹化水好吸收，拒绝粘腻",
            "温和呵护，有效改善舒缓干燥粗糙",
          ]
        },
        {
          id: 3,
          name: "example-3",
          label: "肌研极润保湿乳液",
          tip: "锁水8小时，透光一整天",
          price: 105.00,
          series: 1,
          demand: 2,

          imgURL: require('@/assets/images/product/detail/example-y.jpg'),
          unit: "90ml",
          imgIntroURL: [
            require('@/assets/images/product/detail/example-y-1.jpg'),
            require('@/assets/images/product/detail/example-y-2.jpg'),
            require('@/assets/images/product/detail/example-y-3.jpg'),
          ],
          introText: [
            "新增乳酸菌发酵玻尿酸，长效锁水8小时",
            "精简配方",
            "酸奶般丝滑质地，好吸收不粘腻",
          ]
        },
        {
          id: 4,
          name: "example-4",
          label: "水分护手霜",
          tip: "舒缓修复，水润不黏",
          price: 20.90,
          series: 4,
          demand: 3,

          imgURL: require('@/assets/images/product/detail/example-x.jpg'),
          unit: "50g",
          imgIntroURL: [
            require('@/assets/images/product/detail/example-x-1.jpg'),
            require('@/assets/images/product/detail/example-x-2.jpg'),
          ],
          introText: [
            "（1）含小球藻+芦荟+薄荷精华，呵护双手",
            "（2）油包水配方，60%水分含量，保持湿润柔滑",
            "（3）冰淇淋质地，一抹化水，滋润不黏",
            "（4）96%受试者认同：舒缓干燥适感*",
            "（5）添加多重自然精油，清香怡人",
            "*数据来源于阿茉尔资料，65名受试者使用产品15分钟后自我评估结果，实际效果因人而异",
          ]
        },
        {
          id: 5,
          name: "example-5",
          label: "肌研清透美白隔离乳（清爽型）",
          tip: "清透隔离，打造自然妆容",
          price: 130.90,
          series: 1,
          demand: 1,

          imgURL: require('@/assets/images/product/detail/example-y.jpg'),
          unit: "30g",
          imgIntroURL: [
            require('@/assets/images/product/detail/example-y-1.jpg'),
            require('@/assets/images/product/detail/example-y-2.jpg'),
            require('@/assets/images/product/detail/example-y-3.jpg'),
          ],
          introText: [
            "全波段SPF34 PA+++阻隔紫外线",
            "适合干性及中性肌肤",
          ]
        },
        {
          id: 6,
          name: "example-6",
          label: "肌研极润特浓保湿乳液",
          tip: "弹润紧致，柔韧肤质",
          price: 110.00,
          series: 1,
          demand: 2,

          imgURL: require('@/assets/images/product/detail/example-x.jpg'),
          unit: "90ml",
          imgIntroURL: [
            require('@/assets/images/product/detail/example-x-1.jpg'),
            require('@/assets/images/product/detail/example-x-2.jpg'),
          ],
          introText: [
            "新添加高浓度胶原蛋白，减少皱纹，柔韧肌肤",
            "新添加活性多肽，抚平皱纹，紧致轮廓",
            "5重透明质酸配合，改善干燥，淡化干纹",
          ]
        },
        {
          id: 7,
          name: "example-7",
          label: "肌研极润洁面泡沫",
          tip: "洗出如敷面膜后的水润柔软肌",
          price: 90.00,
          series: 1,
          demand: 5,

          imgURL: require('@/assets/images/product/detail/example-y.jpg'),
          unit: "160ml",
          imgIntroURL: [
            require('@/assets/images/product/detail/example-y-1.jpg'),
            require('@/assets/images/product/detail/example-y-2.jpg'),
            require('@/assets/images/product/detail/example-y-3.jpg'),
          ],
          introText: [
            "卓效保湿成分——双倍透明质酸",
            "丰盈泡沫，洁净肌肤",
          ]
        },
        {
          id: 8,
          name: "example-8",
          label: "肌研极润净透卸妆油",
          tip: "4种修复美容油，温和卸除彩妆",
          price: 130.00,
          series: 1,
          demand: 6,

          imgURL: require('@/assets/images/product/detail/example-x.jpg'),
          unit: "200ml",
          imgIntroURL: [
            require('@/assets/images/product/detail/example-x-1.jpg'),
            require('@/assets/images/product/detail/example-x-2.jpg'),
          ],
          introText: [
            "含橄榄油、霍霍巴油等4种精华油",
            "浓润乳化质地,彻底卸净彩妆",
          ]
        },
        {
          id: 9,
          name: "example-9",
          label: "阿茉尔蜂蜜炭黑面膜",
          tip: "吸走油脂污垢，净毛孔",
          price: 99.90,
          series: 2,
          demand: 4,

          imgURL: require('@/assets/images/product/detail/example-y.jpg'),
          unit: "75g",
          imgIntroURL: [
            require('@/assets/images/product/detail/example-y-1.jpg'),
            require('@/assets/images/product/detail/example-y-2.jpg'),
            require('@/assets/images/product/detail/example-y-3.jpg'),
          ],
          introText: [
            "吸走油脂污垢 净毛孔",
            "微米级”备长炭 深层净透 5倍强吸附力",
            "韩国“黄金蜂蜜”精粹 保湿弹嫩 抗氧化 助修复",
          ]
        },
      ],
      // 系列名称数组（模拟获取后端接口）
      seriesData: [
        {
          id: 0,
          name: "全部",
          collect: [1,2,3,4,5,6],
          icon: require('@/assets/images/product/components/pd-list-s-all.jpg')
        },
        {
          id: 1,
          name: "肌研极润",
          collect: [1,2,5,6],
          icon: require('@/assets/images/product/components/pd-list-s-ji.jpg')
        },
        {
          id: 2,
          name: "面膜系列",
          collect: [4],
          icon: require('@/assets/images/product/components/pd-list-s-mask.jpg')
        },
        {
          id: 3,
          name: "新碧防晒",
          collect: [1],
          icon: require('@/assets/images/product/components/pd-list-s-xin.jpg')
        },
        {
          id: 4,
          name: "手霜系列",
          collect: [3],
          icon: require('@/assets/images/product/components/pd-list-s-shou.jpg')
        }
      ],
      // 系列标题数组（模拟获取后端接口）
      seriesInfoData: [
        {
          id: 0,
          bgURL: null,
          titleURL: null,
          tip: null,
        },
        {
          id: 1,
          bgURL: require('@/assets/images/product/series-1-bg.jpg'),
          titleURL: require('@/assets/images/product/series-1-title.jpg'),
          tip: "肌研极润系列，专研玻尿酸锁水补水",
        },
        {
          id: 2,
          bgURL: require('@/assets/images/product/series-2-bg.jpg'),
          titleURL: require('@/assets/images/product/series-2-title.jpg'),
          tip: "天然原色纯棉面膜，给肌肤带来天然温和的呵护",
        },
        {
          id: 3,
          bgURL: require('@/assets/images/product/series-3-bg.jpg'),
          titleURL: require('@/assets/images/product/series-3-title.jpg'),
          tip: "新碧防晒Solarex-5多波段防晒技术，尽情与阳光玩游戏",
        },
        {
          id: 4,
          bgURL: require('@/assets/images/product/series-4-bg.jpg'),
          titleURL: require('@/assets/images/product/series-4-title.jpg'),
          tip: "阿茉尔全面提供多种肌肤护理方案，关心你护肤的每种需求",
        },
      ],

      /**
       * 需要传入参数：productId
       * type:number
       */
      productId: 0,
      // 当前详情对象
      productDetail: null,
      // 走马灯
      list: [],
      // 小程序码弹窗
      isPopCodeShow: false
    }
  },
  created() {
    this.handleRouterParams()
  },
  methods: {
    /** 跳转到404 */
    to404() {
      this.$router.push({
        path: "/error"
      })
    },
    /** 处理路由参数 */
    handleRouterParams() {
      if(this.$route.query !== undefined && this.$route.query.productId !== undefined) {
        // 模拟查询
        let getId = Number(this.$route.query.productId)
        if(getId >= 0) {
          this.getGoodsDetail(getId);
        } else {
          this.to404()
        }
      }else {
        this.to404()
      }
    },
    /** 面包屑跳转 */
    toHome() {
      console.log("cl")
      this.$router.push({
        path: "/",
      })
    },
    toSkinNoSeries() {
      this.$router.push({
        path: "/skin",
        query: {
          s: 0,
          d: 0,
        }
      })
    },
    toSkin() {
      this.$router.push({
        path: "/skin",
        query: {
          s: this.productDetail.series,
          d: 0,
        }
      })
    },
    /** 获取商品 */
    async getGoodsDetail(goodsId) {
      const res = await apiGoodsDetail(goodsId);
      if(res.data.code === 200) {
        this.productDetail = res.data.data;
        this.calcGoodsInfo(this.productDetail);
      }
    },
    /** 获取完整图片URL */
    getProductURL(url) {
      if(url && url.startsWith('http')) {
        return url;
      } else {
        return this.$config.PICTURE_URL + url;
      }
    },
    /** 拼接图片URL */
    calcGoodsInfo(goodsInfo){
      // let data = []
      // data.push(goodsInfo.mainPic)
      if(goodsInfo.headPic){
        // this.list = JSON.parse(goodsInfo.headPic) ;
        let arr = goodsInfo.headPic.split(",");
        let newArr = []
        if(arr.length>0){
          newArr = arr.map(item => this.$config.PICTURE_URL+`${item}`);
        }
        this.list = newArr
      }else{
        let arr =[]
        arr.push(goodsInfo.mainPic)
        this.list = arr
      }

      let str = this.productDetail.details;
      let picHeader = this.$config.PICTURE_URL_TEXT;
      // 使用正则表达式匹配 <img ... src="/console..."> 中的 src 属性值
      const regex = /<img\s+([^>]*)src=["'](\/console[^"']*)["']([^>]*)>/gi;

      // 使用 replace 方法替换 src 属性值
      const newStr = str.replace(regex, (match, beforeSrc, src, afterSrc) => {
        // 在以/console开头的src值前加上https://amr.test.appkubes.com/
        if (src.startsWith('/console')) {
          return `<img ${beforeSrc}src="` + picHeader + `${src}"${afterSrc}>`;
        }
        // 如果src值不是以/console开头，则直接返回原字符串
        return match;
      });
      this.productDetail.details = newStr;
    },
    /** 跳转绝对路径 */
    gotoAbsoluteURL(url) {
      window.open(url);
    }
  }
}
</script>

<template>
  <div>
    <main-header :selected="1"></main-header>
    <div class="container" v-if="productDetail">
      <div class="mini-router">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item class="bread" @click.native="toHome">
            <i class="el-icon-s-home"></i>首页
          </el-breadcrumb-item>
          <el-breadcrumb-item class="bread" @click.native="toSkinNoSeries">护肤</el-breadcrumb-item>
          <el-breadcrumb-item>{{ productDetail.title }}</el-breadcrumb-item>
        </el-breadcrumb>
      </div>
      <div class="product-box">
        <div class="product-img-box">
          <img class="product-img" :src="getProductURL(productDetail.mainPic)" alt>
        </div>
        <div class="product-info">
          <div>
            <div class="product-name">{{productDetail.title}}</div>
            <div class="product-tip">{{productDetail.subTitle}}</div>
            <div class="product-intro-box">
              <div v-for="(item, index) in productDetail.functions" :key="index" class="product-intro-tag">
                {{item.functionName}}
              </div>
            </div>
            <div class="product-price-box">
              <div>
                <span class="product-price">￥{{productDetail.price}}</span>
                <span class="product-unit">/{{productDetail.unit}}</span>
              </div>
              <img class="product-buy-img" :src="require('/src/assets/images/buy.jpg')" alt @click="isPopCodeShow = true">
            </div>
          </div>
        </div>
      </div>
      <div class="intro-box">
        <div class="intro-content" v-html="productDetail.details"></div>
      </div>
    </div>
    <el-dialog title="微信扫码购买商品" :visible.sync="isPopCodeShow" width="400px" center :show-close="false">
      <div class="pop-code-box">
        <img :src="require('/src/assets/images/code-MALL.jpg')" alt class="pop-code-img">
        <div class="pop-code-or">或</div>
        <div class="mall-item" @click="gotoAbsoluteURL('https://aromore.taobao.com')">
          <div class="mall-item-icon-box">
            <i class="iconfont icon-taobao mall-item-icon"></i>
          </div>
          <div>淘宝企业店</div>
        </div>
      </div>
    </el-dialog>
    <main-footer></main-footer>
  </div>
</template>

<style scoped lang="scss">
@import "~@/styles/products/detail";
</style>

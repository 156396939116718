<script>
/* eslint-disable */
import {apiAddRFI} from "@/common/api";
import config from "@/common/js/config";

export default {
  name: "improve",
  data() {
    return {
      textContent: null,
      ruleForm: {
        rfiType: null,
        title: null,
        description: null,
        user: null,
        age: 18,
        addInfo: null,
        status: 'SUBMIT',
        attachementFileUrl: null
      },
      uploadAction: null,
      rules: {
        rfiType:  [
          { required: true, message: '请选择意见类型', trigger: 'change' }
        ],
        title: [
          { required: true, message: '请填写反馈标题', trigger: 'change' }
        ],
        user: [
          { required: true, message: '请填写用户名', trigger: 'change' }
        ],
      },
      fileList: [],
      isPopCodeShowDiag: false,
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.uploadAction = config.UPLOAD_URL;
    },
    toResolve() {
      this.$router.push({
        path: "/platforms/resolve"
      })
    },
    /** 提交表单 */
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addRFI();
        } else {
          this.$message({
            message: '请完善反馈信息',
            type: 'warning'
          });
        }
      });
    },
    /** 请求接口 */
    async addRFI() {
      const res = await apiAddRFI(this.ruleForm);
      if(res.data.code === 200) {
        this.$alert('反馈提交成功,请勿重复提交', '提示', {
          confirmButtonText: '确定',
          type: 'success'
        });
      } else {
        this.$message({
          message: '提交失败，请联系客服',
          type: 'warning'
        });
      }
    },
    /** 重置表单 */
    resetForm() {
      this.ruleForm = {
        rfiType: null,
        title: null,
        description: null,
        user: null,
        age: 18,
        addInfo: null,
        status: 'SUBMIT'
      }
    },
    /** 附件发送相关 */
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    handleExceed(files, fileList) {
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${ file.name }？`);
    },
    handelSuccess(response, file, fileList) {
      this.ruleForm.attachementFileUrl = response.fileName
    },
    /** 窗口滑动 */
    scrollToElement() {
      this.$refs.refImprove.scrollIntoView({ behavior: 'smooth' }); // 平滑滚动
    }
  }
}
</script>

<template>
  <div>
    <main-header :selected="6"></main-header>
    <div class="improve-page-container">
      <div class="improve-banner">
        <div class="improve-banner-text-box">
          <div class="improve-banner-title">
            请求产品改进
          </div>
          <div class="improve-banner-subtitle">
            共建社区
          </div>
        </div>
        <img class="improve-banner-img" src="@/assets/images/improve/bg-img.png" alt>
      </div>
      <div class="improve-card-box">
        <div class="improve-card card-1">
          <i class="iconfont icon-jianzhu2 improve-card-icon"></i>
          <div class="improve-card-text">
            查看产品改进请求状态
          </div>
          <div class="improve-card-btn" @click="toResolve">
            查看改进请求
          </div>
        </div>
        <div class="improve-card card-2">
          <i class="iconfont icon-jihua improve-card-icon"></i>
          <div class="improve-card-text">
            提交表单快速反馈改进意见
          </div>
          <div class="improve-card-btn" @click="scrollToElement">
            提交反馈表单
          </div>
        </div>
        <div class="improve-card card-3">
          <i class="iconfont icon-dengpao improve-card-icon"></i>
          <div class="improve-card-text">
            如果你对皮肤科学、配方科学以及问题肌诊断机理有了解，可以与我们的专业医肤师讨论交流
          </div>
          <div class="improve-card-btn" @click="isPopCodeShowDiag = true">
            联系医肤师
          </div>
        </div>
        <div class="improve-card card-4">
          <i class="iconfont icon-jiaohu improve-card-icon"></i>
          <div class="improve-card-text">
            发送邮件给
            <span class="improve-card-text-focus">
             rfi@aromore.com
            </span>
            说明你对于Aromore产品改进的意见
          </div>
        </div>
      </div>
      <div class="improve-items-box" ref="refImprove">
        <div class="improve-items-title">
          反馈
        </div>
        <div class="improve-items-subtitle">
          期待来自您对小茉护肤的宝贵意见
        </div>
        <div class="improve-items-input-box">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="80px" class="demo-ruleForm">
            <el-form-item label="意见类型" prop="rfiType">
              <el-radio-group v-model="ruleForm.rfiType">
                <el-radio label="function">产品功能</el-radio>
                <el-radio label="laf">外观感觉</el-radio>
                <el-radio label="requirement">功效需求</el-radio>
                <el-radio label="advise">友好建议</el-radio>
                <el-radio label="other">其他</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="反馈标题" prop="title">
              <el-input v-model="ruleForm.title"></el-input>
            </el-form-item>
            <el-form-item label="反馈内容" prop="description">
              <el-input type="textarea" v-model="ruleForm.description" :rows="4"></el-input>
            </el-form-item>
            <el-form-item label="用户名" prop="user">
              <el-input v-model="ruleForm.user"></el-input>
            </el-form-item>
            <el-form-item label="年龄" prop="age">
              <el-input-number v-model="ruleForm.age" :min="1" :max="120"></el-input-number>
            </el-form-item>
            <el-form-item label="肌肤问题" prop="addInfo">
              <el-input v-model="ruleForm.addInfo"></el-input>
            </el-form-item>
            <el-form-item label="提交附件" prop="attachementFileUrl">
              <el-upload
                  class="upload-demo"
                  :action="uploadAction"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :before-remove="beforeRemove"
                  :limit="1"
                  :on-exceed="handleExceed"
                  :on-success="handelSuccess"
                  :file-list="fileList">
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
        <div class="improve-items-btn" @click="submitForm('ruleForm')">
          提交
        </div>
      </div>
      <div class="improve-committer-box">
        <div class="improve-commit-title">
          成为一个共创者
        </div>
        <div class="improve-commit-card">
          <p class="improve-commit-card-p">
            任何人都可以成为共创者，只要您对Aromore品牌的产品有任何的改进意见，包括但不限制商品配方改进，外观优化，使用问题反馈。一旦相关意见被采纳，我们会给共创者提供相关的奖励
            <span class="improve-commit-card-p-btn">
              立即提交反馈意见
            </span>
          </p>
          <p class="improve-commit-card-p">
            请求产品改进的目的是根据我们配方工厂的定制能力，为消费者提供产品定制(甚至是配方定制)的服务，如果你有这个诉求与需要, 可以
            <span class="improve-commit-card-p-btn">
              联系医肤师
            </span>
          </p>
          <ul class="improve-commit-card-list">
            <li class="improve-commit-card-list-item">
              在专属群中开始讨论皮肤科学以及产品功效改进相关话题，参与产品配方改进
            </li>
            <li class="improve-commit-card-list-item">
              反馈你的肌肤问题，由我们专业的医肤师给你专项诊断，对于特殊困难用户，我们可以免费提供诊疗
            </li>
            <li class="improve-commit-card-list-item">
              积极、有建议性的建议如果被采纳，我们将提供产品赠品奖励
            </li>
          </ul>
        </div>
      </div>
    </div>
    <el-dialog title="微信扫码开始鉴图" :visible.sync="isPopCodeShowDiag" width="300px" center :show-close="false">
      <div class="pop-code-box">
        <img :src="require('/src/assets/images/code-DIAG.png')" alt class="pop-code-img">
      </div>
    </el-dialog>
    <main-footer></main-footer>
  </div>
</template>

<style scoped lang="scss">
.improve-page-container {
  width: 100%;
  padding: 102px 0 0;

  .improve-banner {
    width: 100%;
    height: 350px;
    background-image: url("../../assets/images/improve/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    .improve-banner-text-box {
      font-size: 68px;
      font-weight: 700;
      line-height: 90px;
      width: 780px;

      .improve-banner-title {

      }
      .improve-banner-subtitle {
        -webkit-background-clip: text;
        background-image: linear-gradient(90deg, #437deb, #5ae3f8);
        color: transparent;
        display: inline-block;
      }
    }
    .improve-banner-img {
      height: 350px;
      width: 548px;
    }
  }
  .improve-card-box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -50px;

    .improve-card {
      align-items: center;
      background-color: #fff;
      border-radius: 27px;
      box-shadow: 0 3px 25px rgba(0,0,0,.11);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      height: 320px;
      padding: 30px 40px;
      transform: scale(1);
      transition: .3s;
      width: 320px;

      .improve-card-icon {
        font-size: 60px;
        color: #0097e0;
      }
      .improve-card-text {
        color: #000;
        font-size: 16px;
        height: 110px;
        line-height: 30px;
        margin-top: 30px;
        text-align: center;
        word-break: break-all;

        .improve-card-text-focus {
          color: #0097e0;
        }
      }
      .improve-card-btn {
        border-radius: 40px;
        padding-inline-start: 20px;
        padding-inline-end: 20px;
        width: auto;
        color: #0097e0;
        background-color: transparent;
        box-shadow: none;
        font-size: 16px;
        height: 40px;
        border: 1px solid #0097e0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }
    .improve-card:hover {
      transform: scale(1.1);
    }
  }
  .improve-items-box {
    padding-top: 70px;
    width: 1280px;
    margin: 0 auto;

    .improve-items-title {
      color: #031b3e;
      font-size: 48px;
      font-weight: 700;
      line-height: 1.3;
      padding-bottom: 30px;
      text-align: center;
    }
    .improve-items-subtitle {
      color: #000;
      font-size: 18px;
      line-height: 30px;

      .improve-items-subtitle-btn {
        font-size: 14px;
        height: 32px;
        padding: 4px 15px;
        border-radius: 6px;
        color: #1677ff;
        cursor: pointer;
      }
    }
    .improve-items-input-box {
      margin-top: 20px;
    }
    .improve-items-btn {
      border-radius: 40px;
      padding-inline-start: 20px;
      padding-inline-end: 20px;
      margin: 20px auto 0;
      color: #0097e0;
      background-color: transparent;
      box-shadow: none;
      font-size: 16px;
      height: 40px;
      border: 1px solid #0097e0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 120px;
    }
    .improve-items {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 40px;
      padding-top: 50px;

      .improve-item {
        align-items: center;
        background-color: #fff;
        border: 1px solid hsla(0, 0%, 44%, .6);
        border-radius: 15px;
        box-sizing: border-box;
        display: flex;
        font-size: 24px;
        height: 120px;
        justify-content: space-between;
        margin-bottom: 30px;
        padding: 0 28px;
        width: 700px;

        .improve-item-text {

        }
        .improve-item-btn {
          font-size: 18px;
          color: #1677ff;
          cursor: pointer;
        }
      }
    }
  }
  .improve-committer-box {
    background-image: linear-gradient(119.03deg, rgba(0, 151, 224, .07), rgba(178, 228, 252, .07));
    width: 100%;
    height: 564px;
    margin-top: 40px;

    .improve-commit-title {
      color: #031b3e;
      font-size: 48px;
      font-weight: 700;
      line-height: 1.3;
      padding-bottom: 56px;
      padding-top: 68px;
      text-align: center;
    }
    .improve-commit-card {
      width: 1280px;
      border: 1px solid hsla(0, 0%, 44%, .6);
      border-radius: 15px;
      box-sizing: border-box;
      font-size: 18px;
      margin: 0 auto;
      padding: 32px;
      line-height: 1.5;

      .improve-commit-card-p {
        padding-bottom: 10px;

        .improve-commit-card-p-btn {
          color: #1677ff;
          font-size: 18px;
          padding-left: 8px;
          cursor: pointer;
        }
      }
      .improve-commit-list {

        .improve-commit-list-item {

        }
      }
    }
  }
}
.pop-code-box {
  display: flex;
  align-items: center;
  justify-content: center;

  .pop-code-img {
    width: 200px;
    height: 200px;
  }
}
</style>

import { render, staticRenderFns } from "./open.vue?vue&type=template&id=25738dad&scoped=true"
import script from "./open.vue?vue&type=script&lang=js"
export * from "./open.vue?vue&type=script&lang=js"
import style0 from "./open.vue?vue&type=style&index=0&id=25738dad&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25738dad",
  null
  
)

export default component.exports
<script>
/* eslint-disable */
export default {
  name: "index",
  data() {
    return {
      bicSelect: 1,
      // 扫码
      isPopCodeShow: false,
      isPopCodeShowDiag: false,
      isPopCodeShowAI: false,
      isPopCodeShowBOW: false
    }
  },
  methods: {
    /** 跳转路径 */
    toPath(url) {
      this.$router.push({
        path: url,
      })
    }
  }
}
</script>

<template>
  <div>
    <main-header :selected="4"></main-header>
    <div class="container">
      <div class="head-img-container">
        <img class="head-img" :src="require('/src/assets/images/diagnosis/remote/bg.jpg')" alt>
        <div class="head-content-box">
          <div class="head-txt-box">
            <div class="head-txt-title">医肤档案</div>
            <div class="head-txt-content">阿茉尔提供的一种先进的个性化肌肤管理工具，它结合了大数据、人工智能算法和专业医肤师的诊断，为用户提供全面的肌肤健康服务。</div>
            <div class="head-btn-box">
              <el-button class="head-button" @click="isPopCodeShowDiag = true">建立档案</el-button>
            </div>
          </div>
          <img class="head-img-m" :src="require('/src/assets/images/diagnosis/remote/bg-m.png')" alt>
        </div>
      </div>
    </div>
    <div class="container-body">

      <!-- 大卡片组件 -->
      <div class="bic-container">
        <div class="bi-txt">
          <div class="bi-txt-title">价值与功能</div>
          <!--          <div class="bi-txt-tip">示例标题示例标题</div>-->
        </div>
        <div class="bic-card">
          <div class="bic-main">
            <div class="bic-main-item" v-show="bicSelect === 1">
              <img class="bic-img" :src="require('/src/assets/images/diagnosis/index/imt-1.jpg')" alt>
              <div class="bic-main-text-box">
                <div class="bic-m-t-title-box">
                  <div class="bic-m-t-line"></div>
                  <div class="bic-m-t-title">个性化肌肤分析</div>
                </div>
                <div class="bic-m-t-text-box">
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">对用户的肌肤状况进行详细的分析，包括肤质类型、毛孔状况、水分含量、油脂分泌等</div>
                  </div>
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">能够提供个性化的肌肤诊断报告，帮助用户深入了解自己的肌肤类型和存在的问题</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bic-main-item" v-show="bicSelect === 2">
              <img class="bic-img" :src="require('/src/assets/images/diagnosis/index/imt-2.jpg')" alt>
              <div class="bic-main-text-box">
                <div class="bic-m-t-title-box">
                  <div class="bic-m-t-line"></div>
                  <div class="bic-m-t-title">定制化护肤方案</div>
                </div>
                <div class="bic-m-t-text-box">
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">专业医肤师能够定制专属的护肤方案，包括推荐适合的护肤产品、日常护理步骤和生活习惯调整建议，以解决用户的特定肌肤问题</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bic-main-item" v-show="bicSelect === 3">
              <img class="bic-img" :src="require('/src/assets/images/diagnosis/index/imt-3.jpg')" alt>
              <div class="bic-main-text-box">
                <div class="bic-m-t-title-box">
                  <div class="bic-m-t-line"></div>
                  <div class="bic-m-t-title">长期肌肤健康管理</div>
                </div>
                <div class="bic-m-t-text-box">
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">持续追踪和记录用户的肌肤变化，帮助用户和医肤师监测肌肤的长期健康状况，及时调整护肤策略。</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bic-main-item" v-show="bicSelect === 4">
              <img class="bic-img" :src="require('/src/assets/images/diagnosis/index/imt-4.jpg')" alt>
              <div class="bic-main-text-box">
                <div class="bic-m-t-title-box">
                  <div class="bic-m-t-line"></div>
                  <div class="bic-m-t-title">肌肤问题预警</div>
                </div>
                <div class="bic-m-t-text-box">
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">通过定期更新和分析"医肤档案"中的数据，可以预测潜在的肌肤问题，提前为用户提供预防措施，避免问题的发生或加重。</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bic-aside">
            <div class="bic-button" @click="bicSelect = 1" :class="{'bic-button-selected': bicSelect === 1}">
              <p class="bic-button-txt">个性化肌肤分析</p>
            </div>
            <div class="bic-button" @click="bicSelect = 2" :class="{'bic-button-selected': bicSelect === 2}">
              <p class="bic-button-txt">定制化护肤方案</p>
            </div>
            <div class="bic-button" @click="bicSelect = 3" :class="{'bic-button-selected': bicSelect === 3}">
              <p class="bic-button-txt">长期肌肤健康管理</p>
            </div>
            <div class="bic-button" @click="bicSelect = 4" :class="{'bic-button-selected': bicSelect === 4}">
              <p class="bic-button-txt">肌肤问题预警</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 大图片组件 -->
      <!--      <div class="big-img-box">-->
      <!--        <div class="bi-txt">-->
      <!--          <div class="bi-txt-title">示例标题</div>-->
      <!--          <div class="bi-txt-tip">示例标题示例标题</div>-->
      <!--        </div>-->
      <!--        <img class="bi-img" :src="require('/src/assets/images/ai-doctor/big-img-2.jpg')" alt>-->
      <!--      </div>-->

      <!-- 左右图文组件 -->
      <div class="img-txt-container-left">
        <div class="bi-txt">
          <div class="bi-txt-title">构建医肤档案</div>
          <!--          <div class="bi-txt-tip">示例标题示例标题</div>-->
        </div>
        <div class="t-2-body">
          <img class="t-2-img" alt :src="require('/src/assets/images/diagnosis/index/img-1.jpg')">
          <div class="t-2-cr-box">
            <!--            <div class="t-2-cr">-->
            <!--              <div class="t-2-cr-title">示例标题</div>-->
            <!--              <div class="t-2-cr-text">-->
            <!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">AI肤质测试</div>
              </div>
              <div class="t-2-txt-content">利用人工智能技术进行肤质综合评估，用户通过上传面部照片，AI系统结合图像识别和机器学习算法，综合分析油脂分泌情况、毛孔的清晰度和大小、皮肤的水分含量、敏感发红区域、色素沉着或色斑等，快速准确地判断用户的皮肤现状。</div>
              <div class="head-btn-box">
                <el-button class="head-button" @click="isPopCodeShowAI = true">了解详情</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="t-2-body t-2-body-justify">
          <div class="t-2-cr-box">
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">褒曼医生测试</div>
              </div>
              <div class="t-2-txt-content">根据褒曼医生的十六种肤质分类理论，我们结合时空模型，扩展为6维二十四种肤质分类，进行更深入的肤质评估。这一测试考虑了肌肤的干油性、敏感性、色素沉着倾向和皱纹倾向，为用户产品适用性进行进一步的分析。</div>
              <div class="head-btn-box">
                <el-button class="head-button" @click="isPopCodeShowBOW = true">了解详情</el-button>
              </div>
            </div>
          </div>
          <img class="t-2-img" alt :src="require('/src/assets/images/diagnosis/index/img-2.jpg')">
        </div>
        <div class="t-2-body t-2-body-justify">
          <img class="t-2-img" alt :src="require('/src/assets/images/diagnosis/index/img-3.jpg')">
          <div class="t-2-cr-box">
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">专业医肤师复核</div>
              </div>
              <div class="t-2-txt-content">医肤师会结合AI的分析结果，以及用户上传的6个脸部照片进行更深入的人工图文诊断，避免适配性误判，并可能要求用户提供更多信息或进行进一步的肌肤测试。</div>
              <div class="head-btn-box">
                <el-button class="head-button" @click="isPopCodeShow = true">了解详情</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="t-2-body t-2-body-justify">
          <div class="t-2-cr-box">
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">定制化护肤建议</div>
              </div>
              <div class="t-2-txt-content">根据AI分析和医肤师诊断的结果，为用户提供定制化的护肤建议和产品推荐。这些建议会详细说明如何解决用户的肌肤问题，如何改善肤质，并提供日常护理的指导。</div>
              <div class="head-btn-box">
                <el-button class="head-button" @click="isPopCodeShow = true">了解详情</el-button>
              </div>
            </div>
          </div>
          <img class="t-2-img" alt :src="require('/src/assets/images/diagnosis/index/img-4.jpg')">
        </div>
      </div>

      <!--      <div class="img-txt-container-left">-->
      <!--        <div class="t-2-body">-->
      <!--          <div class="t-2-cr-box">-->
      <!--            <div class="t-2-cr">-->
      <!--              <div class="t-2-cr-title">示例标题</div>-->
      <!--              <div class="t-2-cr-text">-->
      <!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="t-2-cr">-->
      <!--              <div class="t-2-cr-title">示例标题</div>-->
      <!--              <div class="t-2-cr-text">-->
      <!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="t-2-cr">-->
      <!--              <div class="t-2-cr-title">示例标题</div>-->
      <!--              <div class="t-2-cr-text">-->
      <!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="t-2-cr">-->
      <!--              <div class="t-2-cr-title">示例标题</div>-->
      <!--              <div class="t-2-cr-text">-->
      <!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <img class="t-2-img" alt :src="require('/src/assets/images/diagnosis/remote/imt-2.jpg')">-->
      <!--        </div>-->
      <!--      </div>-->

      <!-- 卡片组件 -->
      <div class="card-container">
        <div class="bi-txt">
          <div class="bi-txt-title">日常护肤指南</div>
          <!--          <div class="bi-txt-tip">示例标题示例标题</div>-->
        </div>
        <div class="card-box">
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/diagnosis/index/cr-1.png')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">全面的护肤知识</div>
                <p>提供从基础到高级的护肤概念，包括肌肤类型识别、肌肤生理学、护肤品成分分析等。</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/diagnosis/index/cr-2.png')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">日常护肤与特殊护理</div>
                <p>每天一个小Tips，指导用户如何进行日常护肤，以及在特殊场合或季节变换时如何调整护肤策略。
                  </p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/diagnosis/index/cr-3.png')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">问题肌肤的解决方案</div>
                <p>针对痘痘、敏感、色斑、老化等不同肌肤问题，提供专业的治疗建议和预防措施。</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/diagnosis/index/cr-4.png')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">专业医疗建议</div>
                <p>提供专业皮肤科医生的意见，对于严重的肌肤问题，指导读者何时寻求专业医疗帮助。</p>
              </div>
            </div>
          </el-card>
        </div>
      </div>

    </div>
    <el-dialog title="微信扫码咨询客服" :visible.sync="isPopCodeShow" width="300px" center :show-close="false">
      <div class="pop-code-box">
        <img :src="require('/src/assets/images/code-consult.png')" alt class="pop-code-img">
      </div>
    </el-dialog>
    <el-dialog title="微信扫码进入测肤" :visible.sync="isPopCodeShowAI" width="300px" center :show-close="false">
      <div class="pop-code-box">
        <img :src="require('/src/assets/images/code-AI.jpg')" alt class="pop-code-img">
      </div>
    </el-dialog>
    <el-dialog title="微信扫码进入测试" :visible.sync="isPopCodeShowBOW" width="300px" center :show-close="false">
      <div class="pop-code-box">
        <img :src="require('/src/assets/images/code-BOWMAN.jpg')" alt class="pop-code-img">
      </div>
    </el-dialog>
    <el-dialog title="微信扫码开始鉴图" :visible.sync="isPopCodeShowDiag" width="300px" center :show-close="false">
      <div class="pop-code-box">
        <img :src="require('/src/assets/images/code-DIAG.png')" alt class="pop-code-img">
      </div>
    </el-dialog>
    <main-footer></main-footer>
  </div>
</template>

<style scoped lang="scss">
@import "~@/styles/diagnosis/index";
</style>

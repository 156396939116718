<script>
/* eslint-disable */
export default {
  name: "bowmanTest"
}
</script>

<template>
  <div>
    <main-header :selected="3" class="header"></main-header>
    <div>
      <div class="container" id="index-0">
        <div class="q-canvas q-color">
          <div class="i0-box">
            <div class="i0-title">鲍曼医生测试</div>
            <div>
              <div class="i0-theme">A R O M O R E</div>
              <div class="i0-theme-cn">阿 茉 尔</div>
            </div>
            <div class="i0-button">
              <div class="i0-title">开始测试（未上线）</div>
              <i class="el-icon-arrow-down"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<style scoped lang="scss">
@import "~@/styles/tested/bowmanTest";
</style>

<script>
/* eslint-disable */

import {apiGetFeature} from "@/common/api";

export default {
  name: "index",
  data() {
    return {
      // 大轮播图URL
      carouselURLList: [
        {
          id: 1,
          img: require("@/assets/images/index/banner-2.jpg"),
          goodsId: 49
        },
        {
          id: 2,
          img: require("@/assets/images/index/banner-1.jpg"),
          goodsId: 48
        },
        {
          id: 3,
          img: require("@/assets/images/index/banner-3.jpg"),
          goodsId: 54
        },
      ],
      // 小轮播图URL
      carouselURLListM: [
        {
          indexRedirectUri: "pkGoods/goodsDetail/goodsDetail?goodsId=49",
          mainProductTitle: "净颜祛痘霜",
          productLineDescription: "植物配方，祛痘不留印",
          productLineLogo: require("@/assets/images/product/c-1.jpg")
        }, {
          indexRedirectUri: "pkGoods/goodsDetail/goodsDetail?goodsId=51",
          mainProductTitle: "舒缓淡纹焕肤精华乳",
          productLineDescription: "焕活肌底，抵抗岁月侵蚀",
          productLineLogo: require("@/assets/images/product/c-2.jpg")
        }, {
          indexRedirectUri: "pkGoods/goodsDetail/goodsDetail?goodsId=53",
          mainProductTitle: "舒缓透润精华液",
          productLineDescription: "敏感肌适用，褪红祛痘印",
          productLineLogo: require("@/assets/images/product/c-3.jpg")
        }, {
          indexRedirectUri: "pkGoods/goodsDetail/goodsDetail?goodsId=47",
          mainProductTitle: "赋活新肌焕肤霜",
          productLineDescription: "告别暗沉，水光焕亮",
          productLineLogo: require("@/assets/images/product/c-4.jpg")
        },
      ],
      // 新小轮播图列表
      feaList: [],
    }
  },
  created() {
    // 获取功效产品线
    // this.getFeature();
    this.feaList = this.carouselURLListM;
  },
  methods: {
    /** 选择商品 */
    onProductClick(item) {
      let str = item.indexRedirectUri;
      let regex = /goodsId=(\d+)/;
      let match = str.match(regex);
      if (match) {
        let goodsId = match[1];
        this.$router.push({
          path: '/detail',
          query: {
            productId: goodsId,
          }
        })
      }
    },
    /** 获取功效产品线 */
    async getFeature() {
      const res = await apiGetFeature();
      if(res.data.code === 200) {
        this.feaList = res.data.rows;
        for(let i = 0; i < this.feaList.length; i++) {
          this.feaList[i].productLineLogo = this.$config.PICTURE_URL + this.feaList[i].productLineLogo;
        }
      }
    },
    /** 产品橱窗点击商品 */
    onWindowClick(id) {
      this.$router.push({
        path: '/detail',
        query: {
          productId: id,
        }
      })
    },
    /** 走马灯点击 */
    onBannerClick(id) {
      console.log("dwadwdadawdad")
      this.$router.push({
        path: '/detail',
        query: {
          productId: id,
        }
      })
    }
  }
}
</script>

<template>
  <div>
    <main-header></main-header>

    <div class="container">
      <div class="carousel-box">
        <el-carousel height="506px">
          <el-carousel-item v-for="item in carouselURLList" :key="item.id">
            <div class="carousel-img-container">
              <img class="carousel-img" alt :src="item.img" @click.stop="onBannerClick(item.goodsId)">
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="carousel-m-box">
        <div class="intro-title">
          <div class="intro-title-cn">功效产品</div>
          <div class="intro-title-en">Recommend to You</div>
        </div>
        <div class="carousel-m-container">
          <el-carousel :interval="4000" type="card" height="450px">
            <el-carousel-item v-for="(item, index) in feaList" :key="index">
              <div class="carousel-m-img-fix" @click="onProductClick(item)">
<!--                   :style="{backgroundImage: 'url(' + item.bg + ')'}">-->
                <div class="carousel-m-img-title">{{item.mainProductTitle}}</div>
                <div class="carousel-m-img-text">{{item.productLineDescription}}</div>
                <div class="carousel-m-img-box">
                  <img class="carousel-m-img" alt :src="item.productLineLogo">
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
      <div class="intro2-box">
        <div class="intro-title">
          <div class="intro-title-cn">产品橱窗</div>
          <div class="intro-title-en">Show to You</div>
        </div>
        <div class="intro2-container">
          <div @click="onWindowClick(47)">
            <img class="intro2-img-1" alt :src="require('@/assets/images/index/m_2.jpg')">
          </div>
          <div>
            <div @click="onWindowClick(48)">
              <img class="intro2-img-2" alt :src="require('@/assets/images/index/m_3.jpg')">
            </div>
            <div class="intro2-container-m">
              <div @click="onWindowClick(49)">
                <img class="intro2-img-3" alt :src="require('@/assets/images/index/m_4.jpg')">
              </div>
              <div @click="onWindowClick(46)">
                <img class="intro2-img-3" alt :src="require('@/assets/images/index/m_5.jpg')">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <main-footer></main-footer>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/styles/index";
</style>

<script>
/* eslint-disable */
export default {
  name: "ai",
  data() {
    return {
      carouselURLList: [
        {
          id: 0,
          img: require('/src/assets/images/diagnosis/bg-1.jpg')
        },
        {
          id: 1,
          img: require('/src/assets/images/diagnosis/bg-2.jpg')
        },
        {
          id: 2,
          img: require('/src/assets/images/diagnosis/bg-3.jpg')
        }
      ],
      // 小程序码弹窗
      isPopCodeShow: false
    }
  },
  methods: {

  }
}
</script>

<template>
  <div>
    <main-header :selected="3"></main-header>
    <div class="container">
      <div class="carousel-box">
        <el-carousel height="780px">
          <el-carousel-item>
            <div class="carousel-img-container">
              <img class="carousel-img" alt :src="carouselURLList[0].img">
              <div class="ca-text-box">
                <div class="ca-text-title">拍照测肤</div>
                <div class="ca-text-title-en">
                  Photographic skin measurement
                </div>
                <div class="ca-text-margin-1">
                  <img :src="require('/src/assets/images/diagnosis/bg-1-icon-1.png')" alt>
                  面部皮肤分析
                </div>
                <div class="ca-text-margin-2">
                  <img :src="require('/src/assets/images/diagnosis/bg-1-icon-2.png')" alt>
                  AI智能测肤
                </div>
                <div class="ca-text-margin-3">
                  <span class="ca-text-num">9</span>项皮肤数据 +
                  <span class="ca-text-num">7</span>中皮肤问题
                </div>
                <div class="ca-text-margin-4">
                  <el-button class="ca-text-button" @click="isPopCodeShow = true">皮肤AI分析</el-button>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="carousel-img-container">
              <img class="carousel-img" alt :src="carouselURLList[1].img">
              <div class="ca-text-box-2">
                <div class="ca-text-title-en">
                  Multi spectral skin test
                </div>
                <div class="ca-text-title">多光谱皮肤检测</div>
                <div class="ca-text-margin-4">
                  <el-button class="ca-text-button" @click="isPopCodeShow = true">皮肤AI分析</el-button>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="carousel-img-container">
              <img class="carousel-img" alt :src="carouselURLList[2].img">
              <div class="ca-text-box-3">
                <div class="ca-text-title">五官分析</div>
                <div class="ca-text-title-en">
                  Photographic skin measurement
                </div>
                <div class="ca-text-margin-1">
                  <img :src="require('/src/assets/images/diagnosis/bg-1-icon-1.png')" alt>
                  面部特征分析
                </div>
                <div class="ca-text-margin-4">
                  <el-button class="ca-text-button" @click="isPopCodeShow = true">皮肤AI分析</el-button>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="card-container">
        <div class="card-title-box">
          <div class="cr-title">产品服务</div>
          <div class="cr-title-en">Product service</div>
        </div>
        <div class="cr-main">
          <div class="cr-box">
            <div class="cr-img-box">
              <img class="cr-img" :src="require('/src/assets/images/diagnosis/cr-1-1.jpg')" alt>
              <div class="cr-icon">
                <img :src="require('/src/assets/images/diagnosis/cr-1-1-i.jpg')" alt>
              </div>
            </div>
            <div class="cr-txt">AI皮肤检测</div>
          </div>
          <div class="cr-box">
            <div class="cr-img-box">
              <img class="cr-img" :src="require('/src/assets/images/diagnosis/cr-1-2.jpg')" alt>
              <div class="cr-icon">
                <img :src="require('/src/assets/images/diagnosis/cr-1-2-i.jpg')" alt>
              </div>
            </div>
            <div class="cr-txt">人脸特征识别</div>
          </div>
          <div class="cr-box">
            <div class="cr-img-box">
              <img class="cr-img" :src="require('/src/assets/images/diagnosis/cr-1-3.jpg')" alt>
              <div class="cr-icon">
                <img :src="require('/src/assets/images/diagnosis/cr-1-3-i.jpg')" alt>
              </div>
            </div>
            <div class="cr-txt">多光谱高清皮肤检测</div>
          </div>
          <div class="cr-box">
            <div class="cr-img-box">
              <img class="cr-img" :src="require('/src/assets/images/diagnosis/cr-1-4.jpg')" alt>
              <div class="cr-icon">
                <img :src="require('/src/assets/images/diagnosis/cr-1-4-i.jpg')" alt>
              </div>
            </div>
            <div class="cr-txt">接触式皮肤检测</div>
          </div>
        </div>
      </div>
      <div class="card-container-2" >
        <div class="card-title-box-2">
          <div class="cr-title">优势能力</div>
          <div class="cr-title-en">Product advantage</div>
        </div>
        <div class="card-box-2">
          <div class="cr-2">
            <div class="cr-2-img-box">
              <img class="cr-2-img" :src="require('/src/assets/images/diagnosis/cr-2-1.jpg')" alt>
            </div>
            <div class="cr-2-txt">近百万皮肤样本数据</div>
          </div>
          <div class="cr-2">
            <div class="cr-2-img-box">
              <img class="cr-2-img" :src="require('/src/assets/images/diagnosis/cr-2-2.jpg')" alt>
            </div>
            <div class="cr-2-txt">一流的人工智能团队</div>
          </div>
          <div class="cr-2">
            <div class="cr-2-img-box">
              <img class="cr-2-img" :src="require('/src/assets/images/diagnosis/cr-2-3.jpg')" alt>
            </div>
            <div class="cr-2-txt">领先的深度学习皮肤算法</div>
          </div>
          <div class="cr-2">
            <div class="cr-2-img-box">
              <img class="cr-2-img" :src="require('/src/assets/images/diagnosis/cr-2-4.jpg')" alt>
            </div>
            <div class="cr-2-txt">灵活集成的按需定制产品</div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="微信扫码进入测肤" :visible.sync="isPopCodeShow" width="300px" center :show-close="false">
      <div class="pop-code-box">
        <img :src="require('/src/assets/images/code-AI.jpg')" alt class="pop-code-img">
      </div>
    </el-dialog>
    <main-footer></main-footer>
  </div>
</template>

<style scoped lang="scss">
@import "~@/styles/tested/ai";
</style>

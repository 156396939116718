/* eslint-disable */
import request from "@/common/js/request";
import config from "@/common/js/config";

/** 定制-分类列表 */
export function apiGetCateList() {
    return request({
        method: "get",
        url: config.REQUEST_URLS.cateList,
        params: {
            page: 1,
            size: 100,
            categoryAttr: "HOME_POS",
        }
    })
}

/** 定制-商品列表 */
export function apiGetGoodsList(categoryid) {
    return request({
        method: 'get',
        url: config.REQUEST_URLS.goodsList,
        params: {
            page: 1,
            size: 100,
            categoryId: categoryid
        }
    })
}

/** 定制-商品详情 */
export function apiGoodsDetail(goodsid) {
    return request({
        method: 'get',
        url: config.REQUEST_URLS.goodsDetail,
        params: {
            goodsId: goodsid
        }
    })
}

/** 首页-功效产品线 */
export function apiGetFeature() {
    return request({
        method: 'get',
        url: config.REQUEST_URLS.homeFeature,
        params: {
            pageSize: 100
        }
    })
}

/** 反馈-提交 */
export function apiAddRFI(form) {
    return request({
        method: 'post',
        url: config.REQUEST_URLS.rfiAdd,
        data: form
    })
}

/** 反馈-获取列表 */
export function apiGetRFIList() {
    return request({
        method: 'get',
        url: config.REQUEST_URLS.rfiList,
        params: {
            pageSize: 100
        }
    })
}

/** 反馈-获取列表 */
export function apiGetRFIDetail(id) {
    return request({
        method: 'get',
        url: config.REQUEST_URLS.rfiDetail,
        params: {
            rfiId: id
        }
    })
}

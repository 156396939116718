import Vue from "vue";
import Vuex from "vuex";
// 使用Vuex组件
Vue.use(Vuex)

export default new Vuex.Store({
    actions:{},
    mutations:{},
    state:{}
})

<script>
/* eslint-disable */
import {apiGetCateList, apiGetGoodsList} from "@/common/api";

export default {
  name: "index",
  data() {
    return {
      /**
       * 传入系列和需求的id，0-全选
       * 系列：1-肌研，2-面膜，3-防晒，4-手霜
       * 需求：1-防晒，2-乳液，3-手霜，4-面膜，5-洁面，6-卸妆
       */
      series: 0,
      demand: 0,

      // 系列数组（模拟获取后端接口）
      seriesData: [
        {
          id: 0,
          name: "全部",
          collect: [1,2,3,4,5,6],
          icon: require('@/assets/images/product/components/pd-list-s-all.jpg')
        },
        {
          id: 1,
          name: "肌研极润",
          collect: [1,2,5,6],
          icon: require('@/assets/images/product/components/pd-list-s-ji.jpg')
        },
        {
          id: 2,
          name: "面膜系列",
          collect: [4],
          icon: require('@/assets/images/product/components/pd-list-s-mask.jpg')
        },
        {
          id: 3,
          name: "新碧防晒",
          collect: [1],
          icon: require('@/assets/images/product/components/pd-list-s-xin.jpg')
        },
        {
          id: 4,
          name: "手霜系列",
          collect: [3],
          icon: require('@/assets/images/product/components/pd-list-s-shou.jpg')
        }
      ],
      // 需求数组（模拟获取后端接口）
      demandData: [
        {
          id: 0,
          name: "全部",
          icon: require('@/assets/images/product/components/pd-list-d-all.jpg')
        },
        {
          id: 1,
          name: "日用防晒",
          icon: require('@/assets/images/product/components/pd-list-d-fang.jpg')
        },
        {
          id: 2,
          name: "乳液",
          icon: require('@/assets/images/product/components/pd-list-d-ru.jpg')
        },
        {
          id: 3,
          name: "手霜",
          icon: require('@/assets/images/product/components/pd-list-d-shou.jpg')
        },
        {
          id: 4,
          name: "面膜",
          icon: require('@/assets/images/product/components/pd-list-d-mask.jpg')
        },
        {
          id: 5,
          name: "洁面",
          icon: require('@/assets/images/product/components/pd-list-d-jie.jpg')
        },
        {
          id: 6,
          name: "卸妆",
          icon: require('@/assets/images/product/components/pd-list-d-xie.jpg')
        },
      ],
      // 过滤后的需求数组
      demandDataHandled: [],

      /**
       * 样例商品
       * example-1;50惠育发
       * example-2;新碧防晒;日用防晒
       * example-3;肌研极润;乳液
       * example-4;手霜及身体乳;手霜
       * example-5;肌研极润;日用防晒
       * example-6;肌研极润;乳液
       * example-7;肌研极润;洁面
       * example-8;肌研极润;卸妆
       */
      // 产品列表数组（模拟获取后端接口）
      productData: [
        {
          id: 2,
          name: "example-2",
          label: "双重保湿水感防晒露SPF50",
          tip: "防晒黑，防晒干，清透薄",
          price: 109.90,
          series: 3,
          demand: 1,
          imgURL: require('@/assets/images/product/example-2-list.jpg'),
        },
        {
          id: 3,
          name: "example-3",
          label: "肌研极润保湿乳液",
          tip: "锁水8小时，透光一整天",
          price: 105.00,
          series: 1,
          demand: 2,
          imgURL: require('@/assets/images/product/example-3-list.jpg'),
        },
        {
          id: 4,
          name: "example-4",
          label: "水分护手霜",
          tip: "舒缓修复，水润不黏",
          price: 20.90,
          series: 4,
          demand: 3,
          imgURL: require('@/assets/images/product/example-4-list.jpg'),
        },
        {
          id: 5,
          name: "example-5",
          label: "肌研清透美白隔离乳（清爽型）",
          tip: "清透隔离，打造自然妆容",
          price: 130.90,
          series: 1,
          demand: 1,
          imgURL: require('@/assets/images/product/example-5-list.jpg'),
        },
        {
          id: 6,
          name: "example-6",
          label: "肌研极润特浓保湿乳液",
          tip: "弹润紧致，柔韧肤质",
          price: 110.00,
          series: 1,
          demand: 2,
          imgURL: require('@/assets/images/product/example-6-list.jpg'),
        },
        {
          id: 7,
          name: "example-7",
          label: "肌研极润洁面泡沫",
          tip: "洗出如敷面膜后的水润柔软肌",
          price: 90.00,
          series: 1,
          demand: 5,
          imgURL: require('@/assets/images/product/example-7-list.jpg'),
        },
        {
          id: 8,
          name: "example-8",
          label: "肌研极润净透卸妆油",
          tip: "4种修复美容油，温和卸除彩妆",
          price: 130.00,
          series: 1,
          demand: 6,
          imgURL: require('@/assets/images/product/example-8-list.jpg'),
        },
        {
          id: 9,
          name: "example-9",
          label: "阿茉尔蜂蜜炭黑面膜",
          tip: "吸走油脂污垢，净毛孔",
          price: 99.90,
          series: 2,
          demand: 4,
          imgURL: require('@/assets/images/product/example-9-list.jpg'),
        },
      ],
      // 过滤后的产品数组
      productDataHandled: [],

      // 系列标题数组（模拟获取后端接口）
      seriesInfoData: [
        {
          id: 0,
          bgURL: null,
          titleURL: null,
          tip: null,
        },
        {
          id: 1,
          bgURL: require('@/assets/images/product/series-1-bg.jpg'),
          titleURL: require('@/assets/images/product/series-1-title.jpg'),
          tip: "肌研极润系列，专研玻尿酸锁水补水",
        },
        {
          id: 2,
          bgURL: require('@/assets/images/product/series-2-bg.jpg'),
          titleURL: require('@/assets/images/product/series-2-title.jpg'),
          tip: "天然原色纯棉面膜，给肌肤带来天然温和的呵护",
        },
        {
          id: 3,
          bgURL: require('@/assets/images/product/series-3-bg.jpg'),
          titleURL: require('@/assets/images/product/series-3-title.jpg'),
          tip: "新碧防晒Solarex-5多波段防晒技术，尽情与阳光玩游戏",
        },
        {
          id: 4,
          bgURL: require('@/assets/images/product/series-4-bg.jpg'),
          titleURL: require('@/assets/images/product/series-4-title.jpg'),
          tip: "阿茉尔全面提供多种肌肤护理方案，关心你护肤的每种需求",
        },
      ],

      // 图片-全分类
      tabIconAll: null,
      // 分类列表
      cateList: [],
      nowCateSelected: 0,
      // 商品列表
      productList: [],
    }
  },
  created() {
    this.getImg();
    // 获取商品列表
    this.handleQueryParams();
    this.onCreate();
  },
  watch: {
  },
  methods: {
    getImg() {
      this.tabIconAll = this.$img.MALL.cate_all;
    },

    /** 处理查询参数 */
    handleQueryParams() {
      if(this.$route.query.s !== undefined) {
        this.nowCateSelected = Number(this.$route.query.s)
      }
      // 获取分类列表
      this.getCateList();
    },

    /**
     * 初始化
     */
    onCreate() {
      this.demandDataHandled = this.demandData
      this.HandleDemandDataWhenSeriesChange()
      this.handleProductDataWhenChange()
    },

    /** 选择系列 */
    onSeriesClick(id) {
      this.series = id
      this.HandleDemandDataWhenSeriesChange()
      this.handleProductDataWhenChange()
    },
    /** 处理需求数组 */
    HandleDemandDataWhenSeriesChange() {
      this.demandDataHandled = []
      this.demand = 0
      this.demandDataHandled.push(this.demandData[0])
      for(let i = 0; i < this.seriesData[this.series].collect.length; i++) {
        this.demandDataHandled.push(this.demandData[this.seriesData[this.series].collect[i]])
      }
    },
    /** 选择需求 */
    onDemandClick(id) {
      this.demand = id
      this.handleProductDataWhenChange()
    },

    /** 根据参数注入需要显示的产品数组 */
    handleProductDataWhenChange() {
      this.productDataHandled = []
      this.productDataHandled = this.productData.filter((item) => {
        return (item.series === this.series || this.series === 0) && (item.demand === this.demand || this.demand === 0)
      })
    },

    /** 选择商品 */
    onProductClick(id) {
      this.$router.push({
        path: '/detail',
        query: {
          productId: id,
        }
      })
    },

    /** 获取分类列表 */
    async getCateList() {
      this.cateList = [];
      this.cateList.push({
        name: "全部",
        pic: this.tabIconAll
      });
      const res = await apiGetCateList();
      if(res.data.code === 200) {
        this.cateList = this.cateList.concat(res.data.data);
        // 拼图
        for(let i = 1; i < this.cateList.length; i++) {
          this.cateList[i].pic = this.$config.PICTURE_URL + this.cateList[i].pic;
        }
        await this.getGoodsList(this.cateList[this.nowCateSelected].categoryId);
      }
    },
    /** 分类点击 */
    onCateClick(item, index) {
      this.nowCateSelected = index;
      let categoryid = null;
      if(index !== 0) {
        categoryid = item.categoryId;
      }
      this.getGoodsList(categoryid);
    },
    /** 获取商品列表 */
    async getGoodsList(categoryid) {
      const res = await apiGetGoodsList(categoryid);
      if(res.data.code === 200) {
        this.productList = res.data.data;
      }
    },
    /** 获取完整图片URL */
    getProductURL(url) {
      if(url && url.startsWith('http')) {
        return url;
      } else {
        return this.$config.PICTURE_URL + url;
      }
    }
  },
}
</script>

<template>
  <div>
    <main-header :selected="1"></main-header>
    <div class="container">
      <div class="title">护肤定制</div>
      <div class="select-container">
        <div class="select-title">
          <div>选择系列</div>
          <div class="select-line"></div>
        </div>
        <div class="select-items">
          <div v-for="(item, index) in cateList" :key="index" @click="onCateClick(item, index)" class="select-item"
               :class="{'select-item select-item-selected':nowCateSelected === index}">
            <img class="select-img" :src="item.pic" alt>
            <div class="select-item-name">{{item.name}}</div>
          </div>
        </div>
      </div>
      <div class="select-list-container">
        <div class="se-li-list">
          <div class="se-li-card" v-for="item in productList" :key="item.id" @click="onProductClick(item.goodsId)">
            <img :src="getProductURL(item.mainPic)" class="card-img" alt>
            <div>{{item.title}}</div>
            <div class="card-price">￥{{item.price.toFixed(2)}}</div>
            <div class="card-line"></div>
            <div class="card-tip">{{item.subTitle}}</div>
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<style scoped lang="scss">
@import "~@/styles/skin/index";
</style>

<script>
/* eslint-disable */
export default {
  name: "about"
}
</script>

<template>
  <div>
    <main-header :selected="6"></main-header>

    <div class="container">
      <div class="long-img-box">
        <div class="long-img-title">
          关于阿茉尔
        </div>
      </div>
      <div class="text1-box">
        <div class="text1-text-box">
          <div class="text1-title">品牌创立</div>
          <div class="text1-text">
            <p>
              2010年，创始人集体以均衡、安全、有效为理念，在广州开始自主研发植物成分与有机组方研发。
            </p>
            <p>
              2014年7月，过四年研究，融汇了细胞生物学、皮肤病理学、植物学、西医、中医、中药等6大领域，成功研制有机护肤品第一代祛痘系列产品。
            </p>
            <p>
              2016年，第一代在线肌肤分型测试系统与远程诊断系统上线，为用户提供个性化的护肤解决方案。
            </p>
            <p>
              2023，注册Aromore商标，芳香（Aroma）与更多（More）的结合, 寓意产品成分的自然和纯净，消费者可以获得更多美丽和健康的皮肤。
            </p>
            <p>
              2024年，与5家韩国、日本、美国的植物成分研究机构进行联合第二代功能性护肤品，并推出阿茉尔系列功效护肤品。
            </p>
          </div>
        </div>
        <div class="text1-img-box">
          <img class="text1-img" alt :src="require('@/assets/images/footer_logo_hd.png')">
        </div>
      </div>
      <div class="text1-box about-end-margin">
        <div class="text1-img-box">
          <img class="develop-img" alt :src="require('@/assets/images/about/about-2.jpg')">
        </div>
        <div class="text1-text-box">
          <div class="text1-title">品牌发展</div>
          <div class="text1-text">
            <p>
              2010年成立于广州市，亦是阿茉尔第一代配方工厂
            </p>
            <p>2014年7月,成立皮肤健康研究所，进行功效护肤品研发</p>
            <p>
              2015年工厂升级，成立15000平的医药级生产车间,并通过ISO22716及GMPC国际标准认证
            </p>
            <p>2017年注册Aromore商标</p>
            <p>
              2023年12月第二代功能性护肤品产品系列投入生产
            </p>
          </div>
        </div>
      </div>
<!--      <div class="text1-box">-->
<!--        <div class="text1-img-box">-->
<!--          <img class="principle-img" alt :src="require('@/assets/images/logo_source.jpg')">-->
<!--        </div>-->
<!--        <div class="text1-text-box">-->
<!--          <div class="text1-title">企业宗旨</div>-->
<!--          <div class="text1-text">-->
<!--            <p>-->
<!--              公司全力工作以服务社会，共创更美好的明日世界。<br>-->
<!--              <span class="text1-text-en">We work wholeheartedly to support society, to help create a better world.</span>-->
<!--            </p>-->
<!--            <p>-->
<!--              为此，公司立志虚心学习，不断自我完善，使生命变得更完满和有意义。<br>-->
<!--              <span class="text1-text-en">For this purpose we are resolved to earn constantly without pretension, to strive toward continuous improvement, and to fulfill our lives meaningfully.</span>-->
<!--            </p>-->
<!--            <p>-->
<!--              公司重视建立与同事及客户间的信任和尊重，并以此为首要任务。<br>-->
<!--              <span class="text1-text-en">We place the highest importance in earning the trust and respect of our associates and our external customers.</span>-->
<!--            </p>-->
<!--            <p>-->
<!--              公司以这个远大的理想为荣，并以不懈的热情积极身体力行。<br>-->
<!--              <span class="text1-text-en">We take pride in setting this supreme goal and we work vigorously to achieve this with relentless passion.</span>-->
<!--            </p>-->
<!--            <p>-->
<!--              公司乐于互励互勉，不断为全球消费者带来一浪接一浪的惊喜。<br>-->
<!--              <span class="text1-text-en">We delight in constantly challenging each other to create waves of happy surprises for our customers worldwide.</span>-->
<!--            </p>-->
<!--            <p>-->
<!--              公司重视[以人为本]的价值，员工是最重要的资产。<br>-->
<!--              <span class="text1-text-en">Our people are our greatest asset.</span>-->
<!--            </p>-->
<!--            <p>-->
<!--              愿以公司努力勤奋的文化、团结合作的精神、热情坚定的决心和领导力、推动公司前进发展。<br>-->
<!--              <span class="text1-text-en">Our hard working culture. spirit of c ose cooperation. determination passion and leadership provide the fuel that drivetrain company.</span>-->
<!--            </p>-->
<!--            <p>-->
<!--              大自然的一草一木、社会上的一人一事、都赋予我们生活的意义，公司承诺关怀付出，并堂存感恩的心。<br>-->
<!--              <span class="text1-text-en">We firmly pledge to serve the people, the society and the environment, and are thankful for our meaningful existence.</span>-->
<!--            </p>-->
<!--            <p>-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="about-mid-title">-->
<!--        品牌发展-->
<!--      </div>-->
<!--      <div class="text1-box about-end-margin">-->
<!--        <div class="text1-text-box">-->
<!--          <div class="text1-title">-->
<!--            品牌发展-->
<!--          </div>-->
<!--          <div class="text1-text">-->
<!--            <p>-->
<!--              2010年成立于广州市，亦是阿茉尔第一代配方工厂-->
<!--            </p>-->
<!--            <p>-->
<!--              2014年7月,成立皮肤健康研究所，进行护肤品研发-->
<!--            </p>-->
<!--            <p>-->
<!--              xxx年注册xxx商标-->
<!--            </p>-->
<!--            <p>-->
<!--              xxx年工厂升级，成立4000平的xx医药级生产车间-->
<!--            </p>-->
<!--            <p>-->
<!--              2023年12月第二代功能性护肤品产品系列投入生产-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="text1-img-box">-->
<!--          <img class="text1-img about-3-img-width" alt :src="require('@/assets/images/about/about-3.jpg')">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="text1-box">-->
<!--        <div class="text1-img-box">-->
<!--          <img class="text1-img about-3-img-width" alt :src="require('@/assets/images/about/about-4.jpg')">-->
<!--        </div>-->
<!--        <div class="text1-text-box">-->
<!--          <div class="text1-title">-->
<!--            低碳生产保护环境-->
<!--          </div>-->
<!--          <div class="text1-text">-->
<!--            <p>-->
<!--              阿茉尔以严谨的生产工序，坚持保护环境、持续履行社会责任的企业形象，积极实施一系列的生产减碳措施，于2022年荣获由世界自然基金会（WWF）香港分会-低碳制造计划（LCMP）的白金标签（Platinum Label）认证！-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="text1-box">-->
<!--        <div class="text1-text-box">-->
<!--          <div class="text1-title">-->
<!--            丰富而周全的产品线-->
<!--          </div>-->
<!--          <div class="text1-text">-->
<!--            <p>-->
<!--              阿茉尔进驻中国三十年，为广大消费者带来周全细心的关爱。现时，阿茉尔中国主要生产润唇膏、男士护肤、女士护肤、防晒、抗痘等护肤品、50惠养发及乐敦眼药水OTC药品等众多系列及产品，以满足国内外不同消费者的需要，产品出口更远至世界各地。-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--        <div class="text1-img-box">-->
<!--          <img class="text1-img about-3-img-width" alt :src="require('@/assets/images/about/about-5.jpg')">-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="text1-box about-end-margin">-->
<!--        <div class="text1-img-box">-->
<!--          <img class="text1-img about-3-img-width" alt :src="require('@/assets/images/about/about-6.jpg')">-->
<!--        </div>-->
<!--        <div class="text1-text-box">-->
<!--          <div class="text1-title">-->
<!--            陪伴中国消费者一起成长-->
<!--          </div>-->
<!--          <div class="text1-text">-->
<!--            <p>-->
<!--              为兑现“阿茉尔，处处关怀”的品牌承诺，阿茉尔努力拓展中国市场，于北京、上海、广州、成都四大城市建立分公司，并同时在大连、天津、哈尔滨等城市进行发展。-->
<!--            </p>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
    </div>

    <main-footer></main-footer>
  </div>
</template>

<style scoped>
@import "~@/styles/about.scss";
</style>

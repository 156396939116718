<script>
/* eslint-disable */
export default {
  name: "mainFooter",
  data() {
    return {

    }
  },
  methods: {
    toCopyright() {
      window.open('https://beian.miit.gov.cn/')
    },
    skip(url) {
      window.open(url);
    },
    toPath(url) {
      this.$router.push({
        path: url
      })
    }
  },
}
</script>

<template>
  <div class="container-footer">
    <!-- 主体 -->
    <div class="box">
      <div class="box-main">
        <div class="logo">
          <img class="logo-img" :src="require('@/assets/images/footer_logo_hd.png')" alt>
        </div>
        <div class="info">
          <div class="info-item">
            <div class="info-title">
              联系方式
            </div>
            <div class="info-text logo-group">
              <img class="logo-mini-img" :src="require('@/assets/images/contact-wechat.png')" alt>
              <img class="logo-mini-img" :src="require('@/assets/images/contact-red.png')" alt>
              <img class="logo-mini-img" :src="require('@/assets/images/contact-tiktok.png')" alt>
              <img class="logo-mini-img" :src="require('@/assets/images/contact-redbook.png')" alt>
            </div>
          </div>
          <div class="info-item">
            <div class="info-title">
              产品热线
            </div>
            <div class="info-text">
              周一至周五 (节假日除外) 9:00-12:00，13:00-16:00
            </div>
          </div>
          <div class="info-item">
            <div class="info-title">
              客服邮箱
            </div>
            <div class="info-text">
              service@aromore.com
            </div>
          </div>
          <div class="info-item">
            <div class="info-title">
              家族网站
            </div>
            <div class="info-text logo-group">
              <img class="logo-mini-img-partner" style="height: 24px;" :src="require('@/assets/images/partner-1.png')" alt @click="skip('https://www.aromore.com')">
              <img class="logo-mini-img-partner" :src="require('@/assets/images/partner-2.png')" alt @click="skip('https://www.douhuu.com.cn')">
            </div>
          </div>
        </div>
      </div>
      <div class="box-footer">
        <div>
          © 阿茉尔公司版权所有 ：阿茉尔（广州）生物科技有限公司
        </div>
        <div class="box-footer-copyright" @click="toCopyright">
          粤ICP备2024168453号-2
        </div>
      </div>
    </div>

    <!-- 二维码盒子 -->
    <div class="code-box">
<!--      <div class="code-btn-line" @click="toPath('/care')">-->
<!--        查阅我们的专业领域-->
<!--      </div>-->
      <div class="code-items-box">
        <div class="code-container">
          <div class="code-img-box">
            <img class="code-img" :src="require('@/assets/images/code-public.png')" alt>
          </div>
          <div class="code-text">小茉护肤公众号</div>
        </div>
        <div class="code-container">
          <div class="code-img-box">
            <img class="code-img" :src="require('@/assets/images/code-mini.png')" alt>
          </div>
          <div class="code-text">微信护肤小程序</div>
        </div>
        <div class="code-container">
          <div class="code-img-box">
            <img class="code-img" :src="require('@/assets/images/code-consult.png')" alt>
          </div>
          <div class="code-text">阿茉尔微信客服</div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/styles/mainFooter.scss";
</style>

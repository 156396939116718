<script>
/* eslint-disable */
export default {
  name: "NotFound"
}
</script>

<template>
  <div>
    <main-header></main-header>
    <div style="width: 100%; display: flex; justify-content: center">
      <img :src="require('/src/assets/images/error400.jpg')"
           alt style="text-align: center; margin-top: 100px; margin-bottom: 200px">
    </div>
    <main-footer></main-footer>
  </div>
</template>

<style scoped lang="scss">

</style>

/* eslint-disable */
// const server = "http://localhost:6012/api/amr" //本地
// const server = "https://amr.test.appkubes.com/api/amr" //测试
// const server = "http://192.168.8.88:6012/api/amr" //wifi
const server = "https://api.aromore.com/api/amr" //生产

const portalServer = "https://amr.appkubes.com/api/portal/file" //生产-portal
//dop 平台域名
// const dop_server = "http://dop.test.appkubes.com"
const dop_server = "https://mall.appkubes.com"
// const RES_URL = "https://res.test.appkubes.com" //资源服务器域名
const RES_URL = "https://res.appkubes.com" //生产
//dop 平台域名
const qqmap_uri = 'https://apis.map.qq.com'
const qqMapKey = "VN4BZ-3UVLP-5E3DW-L6OEI-6OTCS-HZFAW";
// const defultAppKey = "ba5ff26fd3502b098f062456590b43a5"; //测试appkey
const defultAppKey = "1c57e4ecd182b974ef1dd78aa68409a3"; //生产appkey
const defultAppName = "AMR"; //
//第三方API接口域名
const api_file_server = "https://api.yimei.ai/fileSvr/get/";
//文章图片拼接
// const picture_url = "https://amr.test.appkubes.com/console/api/amr"; //普通图片-测试
// const picture_url_text = "https://amr.test.appkubes.com"; //富文本-测试
const picture_url = "https://www.aromore.com/console/api/amr"; //普通图片-生产
const picture_url_text = "https://www.aromore.com"; //富文本-生产
// 上传域名（需要拼接server）
const uploadUrl = "/diagnose/rfi/upload";

export default {
    DEFULTAPPKEY: defultAppKey,
    DEFULTAPPNAME: defultAppName,
    RES_URL: RES_URL,
    WX_APPID: 'wxb3f62b76d1152481', //appId
    API_FILE_URL: api_file_server,
    SERVER_URL: server,
    PICTURE_URL: picture_url,
    PICTURE_URL_TEXT: picture_url_text,
    UPLOAD_URL: server + uploadUrl,
    REQUEST_URLS: {
        getAds: server + '/banner/getList', //获取广告列表
        getByPos: server + '/category/getByPos', //根据指定位置属性获取商品分类
        loginByCode: server + '/user/login/loginByCode', //验证码登录
        loginByPwd: server + '/user/login/loginByPwd', //密码登录
        loginByWx: server + '/user/login/loginByWx', //微信登录
        registerByCode: server + '/user/register/registerByCode', //验证码注册
        getSmsCode: server + '/user/sms/getcode', //获取验证码
        decryptTel: server + '/user/wx/decryptTel', //微信手机号码解密
        wxOpenidInfo: server + '/user/wx/wxOpenidInfo', //获取用户openId
        userInfo: server + '/user/base/getInfo', //获取用户详情

        //用户地址开始
        userAddressList: server + '/user/address/list', //用户地址列表
        userAddressAdd: server + '/user/address/add', //用户添加地址
        userAddressEdit: server + '/user/address/edit', //用户修改地址
        userAddressInfo: server + '/user/address/info', //用户地址详情
        userAddressDel: server + '/user/address/del', //用户删除地址
        //用户地址结束
        modifyUserInfo:server + '/user/base/modifyUserInfo', //修改用户信息
        adList:server + '/shop/biz/ad/list', //广告列表
        adGoodsList:server + '/shop/biz/ad/adGoodsList', //广告商品列表
        cateList:server + '/shop/biz/category/list', //分类列表
        goodsList:server + '/shop/biz/goods/list', //商品列表
        goodsDetail:server + '/shop/biz/goods/goodsDetail', //商品详情
        cagegoryGoodslist:server + '/shop/biz/category/cagegoryGoodslist', //分类商品列表
        //订单
        orderAdd: server + '/shop/biz/order/addOrder', //添加订单
        orderPay: server + '/shop/biz/payment/order/pay/', //订单支付
        orderList: server + '/shop/biz/order/list', //订单列表
        orderDetail: server + '/shop/biz/order/orderDetail', //订单详情
        //购物车
        cartAdd: server + '/shop/biz/cart', //添加购物车
        cartList: server + '/shop/biz/cart/list', //获取购物车列表
        cardDelete: server + '/shop/biz/cart', //删除购物车
        //优惠券
        ticketList: server + '/shop/coupon/list', //优惠券列表
        ticketAllList: server + '/shop/coupon/couponList', //优惠券列表（所有）
        ticketGet: server + '/user/coupon/addUserCoupon', //领券

        //首页
        homeTextList: server + '/cms/category/list' , //获取护肤百科列表
        homeTextDetails: server + '/cms/article/list', //获取护肤百科文章详情
        homeTextDetailById: server + '/cms/article/detail', //获取对应ID护肤百科文章详情
        locationByIP: server + '/user/location/info', //根据IP获取地址位置
        homeBanner: server + '/shop/biz/ad/list', //获取轮播图列表（其他页通用）
        homeTextProduct: server + '/shop/biz/goods/articleGoodsList', //获取文章商品
        homeFeature: server + '/product/line/list', //获取功效产品线
        // 模型
        modelComponentList: server + '/shop/component/list', //成分列表
        modelFunctionalList: server + '/shop/functional/list', //功效列表
        modelSkinPrinciple: server + '/cms/article/skinTypeList', //肤测原理
        modelPrincipleList: server + '/cms/article/principleList', //使用指南
        modelPrincipleDetail: server + '/cms/article/detail', //使用指南（按编码查询）
        modelComponentsOfFunction: server + '/shop/biz/goods/goodsFunctionComponent', //查询商品功效对应的成分
        //护肤咨询（AI）
        consultChat: server + '/ai/chat/dialogue', //对话
        consultList: server + '/ai/chat/topicList', //对话列表
        consultDetail: server + '/ai/chat/dialogueList', //对话详情
        //AI测肤
        aiSkinMeasure: server + '/detect/ai/detectionItem', //传入图片送至AI测肤
        aiDetail: server + '/detect/info/detail', //AI测肤详情（次数）
        aiCharge: server + '/user/acount/recharge', //充值返回chargeId
        aiPay: server + '/shop/biz/payment/account/pay', //充值支付
        aiHistory: server + '/detect/ai/records', //AI测肤记录
        aiResultDetail: server + '/detect/ai', //AI测肤记录详情
        //褒曼医生测试
        bowmanList: server + '/system/exam/questionList', //查询问卷测试列表
        bowmanDetail: server + '/detect/info/baumann/detail', //问卷详情
        bowmanAnswer: server + '/detect/exam/answer/addAnswer', //添加回答
        bowmanResult: server + '/detect/exam/answer/answerScore', //获取结果
        bowmanSetCode: server + '/detect/info/baumann/saveOrUpdateInfo', //设置编码
        bowmanHistory: server + '/detect/exam/answer/answerList', //获取测试记录
        bowmanAllHistory: server + '/detect/exam/answer/allAnswerList', //获取所有记录
        //诊断
        photoUpload: server + '/diagnose/add', //鉴图上传
        photoGetResult: server + '/diagnose/phase/list', //鉴图结果
        photoPhase: server + '/diagnose/detail', //获取阶段
        photoConfirm: server + '/diagnose/phase/confirm', //鉴图确认
        appointmentAdd: server + '/advisor/appoint/add', //预约添加
        appointmentList: server + '/advisor/appoint/quota/list', //预约列表
        appointmentCount: server + '/advisor/appoint/list', //预约人数情况
        appointmentGet: server + '/advisor/appoint/appointListByUserId', //查询预约情况
        appointmentHistory: server + '/advisor/appoint/appointAllList', //查询历史预约列表
        proposalList: server + '/solution/diagnose/list', //查询诊疗方案列表
        proposalDetail: server+ '/solution/diagnose', //诊疗方案详情
        //肤质档案
        recordList: server + '/solution/archive/list', //护肤偏好列表
        recordDetail: server + '/solution/archive', //护肤偏好详情
        recordAdd: server + '/solution/archive/add', //护肤偏好添加
        recordEdit: server + '/solution/archive/edit', //护肤偏好编辑
        recordDel: server + '/solution/archive/del', //护肤偏好删除
        //医肤师
        advisorList: server + '/advisor/list', //医肤师列表
        advisorDetail: server + '/advisor', //医肤师详情
        advisorArticleList: server + '/advisor/article/advisorArticleList', //医肤师文章列表
        //我的
        userCodeDetail: server + '/diagnose/solution/detail', //获取用户编码
        userCodeSave: server + '/diagnose/solution/saveOrUpdateInfo', //设置用户编码
        //任务中心
        taskList: server + '/user/task/list', //任务列表
        taskListUser: server + '/user/task/user/list', //我的任务列表
        taskDetail: server + '/user/task/detail', //任务详情
        taskAdd: server + '/user/task/user/add', //任务完成添加
        //活动
        activityList: server + '/activity/list', //活动列表
        activityDetail: server + '/activity/detail', //活动详情
        activityJoin: server + '/activity/join', //活动参与
        activityJoinList: server + '/activity/joinList', //活动参与列表

        //意见反馈
        rfiAdd: server + '/diagnose/rfi/add', //提交意见表单
        rfiList: server + '/diagnose/rfi/list', //获取意见列表
        rfiDetail: server + '/diagnose/rfi/followList', //获取跟进记录
    },
    PORTAL_URLS: {
        // uploadImage:portalServer+'/app/file/upload'//上传图片
        uploadImage: portalServer + '/base/upload', //上传图片
        uploadBase64: portalServer + '/base/uploadBase64', //上传base64图片
        uploadBase64Temp: 'https://fpg.malltify.com//api/portal/cmpt/file/base/uploadBase64', // 临时64
        uploadBase64Test: server + '/detect/file/uploadBase64' //上传base64图片（测试环境）
    },
}

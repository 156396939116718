import Vue from 'vue'
import Router from 'vue-router'
// 引入静态网页
import index from '../view/index.vue'
import about from '../view/about.vue'
import skin from '../view/skin/index.vue'
import detail from '../view/products/detail.vue'
import error404 from '../view/404.vue'
import search from '../view/search.vue'
import testedResult from '../view/tested/result.vue'
import remote from '../view/diagnosis/remote.vue'
import archives from '../view/diagnosis/archives.vue'
import acne from '../view/acne/index.vue'
import care from '@/view/care/index.vue'
import intro from "../view/acne/intro.vue";
import ai from "../view/tested/ai.vue"
import doctor from "@/view/tested/doctor.vue";
import open from "@/view/platforms/open.vue";
import consultation from "@/view/platforms/consultation.vue";
import bowmanTest from "@/view/tested/bowmanTest.vue";
import archivesIndex from '../view/diagnosis/index.vue';
import improve from "@/view/platforms/improve.vue";
import resolve from "@/view/platforms/resolve.vue";

Vue.use(Router)

// 防止跳转同一路径出异常
const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
}

// 路由表
const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'index',
            component: index,
        },
        {
            path: '/index',
            name: 'index',
            component: index,
        },
        // 服务平台
        {
            path: '/about',
            name: 'about',
            component: about,
        },
        {
            path: '/platforms/open',
            name: 'open',
            component: open,
        },
        {
            path: '/platforms/consultation',
            name: 'consultation',
            component: consultation,
        },
        {
            path: '/platforms/improve',
            name: 'improve',
            component: improve,
        },
        {
            path: '/platforms/resolve',
            name: 'resolve',
            component: resolve,
        },

        // 护肤
        {
            path: '/skin',
            name: 'skin',
            component: skin,
        },
        {
            path: '/detail',
            name: 'detail',
            component: detail,
        },

        // 搜索页
        {
            path: '/search',
            name: 'search',
            component: search,
        },

        // 测肤（新）
        {
            path: '/ai',
            name: 'ai',
            component: ai,
        },
        {
            path: '/doctor',
            name: 'doctor',
            component: doctor,
        },
        {
            path: '/doctor/bowmanTest',
            name: 'bowmanTest',
            component: bowmanTest,
        },

        {
            path: '/diagnosis/archives/result',
            name: 'result',
            component: testedResult
        },
        // 诊断
        {
            path: '/diagnosis/index',
            name: 'archivesIndex',
            component: archivesIndex,
        },
        {
            path: '/diagnosis/remote',
            name: 'remote',
            component: remote,
        },
        {
            path: '/diagnosis/archives',
            name: 'archives',
            component: archives,
        },

        // 祛痘
        {
            path: '/acne',
            name: 'acne',
            component: acne,
        },
        {
            path: '/acne/intro',
            name: 'intro',
            component: intro,
        },
        {
            path: '/care',
            name: 'care',
            component: care,
        },

        // 404
        {
            path: '/:pathMatch(.*)*',
            name: 'NotFound',
            component: error404,
        }
    ],
})

// 使用全局前置守卫
router.beforeEach((to, from, next) => {
    // 回到页面顶部
    window.scrollTo(0, 0);
    // 确保一定要调用 next()
    next();
});

export default router



/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
// 路由
import router from "@/router/router";
// 页眉和页脚
import mainHeader from "@/components/mainHeader.vue";
import mainFooter from "@/components/mainFooter.vue";
// elementUI
import ElementUI from "element-ui";
import 'element-ui/lib/theme-chalk/index.css';
// 全局样式
import '@/styles/global.scss'
// 滚动条组件
import VueSmoothScroll from "vue2-smooth-scroll";
// 引入Vuex
import store from "@/store";
// 引入cookie
import jsCookie from "js-cookie";
// 引入图标
import img from "@/common/amrImg";
// 引入配置文件
import config from "@/common/js/config";

Vue.prototype.$cookie = jsCookie;

Vue.config.productionTip = false

// 全局引入：页头页脚
Vue.component('main-header', mainHeader)
Vue.component('main-footer', mainFooter)
// 使用elementUI
Vue.use(ElementUI);
// 使用滚动条
Vue.use(VueSmoothScroll)

Vue.prototype.$img = img;
Vue.prototype.$config = config;

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App),
}).$mount('#app')


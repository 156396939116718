<script>
/* eslint-disable */
export default {
  name: "index",
  data() {
    return {
      /**
       * 样例商品
       */
      // 产品列表数组（模拟获取后端接口）
      productData: [
        {
          id: 2,
          name: "example-2",
          label: "胶原蛋白精华护肤水乳",
          tip: "新一代生物护肤 全天状态稳稳",
          price: 356.00,
          series: 3,
          demand: 1,
          imgURL: require('@/assets/images/acne/item-1-1.jpg'),
          imgURL2: require('@/assets/images/acne/item-1-0.jpg'),
        },
        {
          id: 3,
          name: "example-3",
          label: "阿茉尔护肤爽肤水",
          tip: "清爽保湿",
          price: 198.00,
          series: 1,
          demand: 2,
          imgURL: require('@/assets/images/acne/item-2-1.jpg'),
          imgURL2: require('@/assets/images/acne/item-2-0.jpg'),
        },
        {
          id: 4,
          name: "example-2",
          label: "胶原蛋白精华护肤水乳",
          tip: "新一代生物护肤 全天状态稳稳",
          price: 356.00,
          series: 3,
          demand: 1,
          imgURL: require('@/assets/images/acne/item-1-1.jpg'),
          imgURL2: require('@/assets/images/acne/item-1-0.jpg'),
        },
        {
          id: 5,
          name: "example-3",
          label: "阿茉尔护肤爽肤水",
          tip: "清爽保湿",
          price: 198.00,
          series: 1,
          demand: 2,
          imgURL: require('@/assets/images/acne/item-2-1.jpg'),
          imgURL2: require('@/assets/images/acne/item-2-0.jpg'),
        },
      ],
      // 过滤后的产品数组
      productDataHandled: [],
      // 搜索关键字
      searchKey: "",

      /** 新护肤字段 */
      // 控制开关
      switchText: "查看更多",

    }
  },
  created() {
    this.productDataHandled = this.productData
  },
  methods: {
    /** 选择商品 */
    onProductClick(id) {
      // this.$router.push({
      //   path: '/detail',
      //   query: {
      //     productId: id,
      //   }
      // })
    },
    onMoreClick() {
      if (this.switchText === '收起') {
        this.switchText = '查看更多'
      } else {
        this.switchText = '收起'
      }
    },
    onPageOpen() {
      window.open("https://mp.weixin.qq.com/s?__biz=MzkzOTYzMTc1Nw==&mid=2247484116&idx=1&sn=1e29f4742896c92c2f83d17882b0fc58&chksm=c2ecb736f59b3e20874a048c12ae73ab6d039b6e6ebd3b48104da8634edcbf21db50d563685e#rd");
    },
    skipTo(url) {
      window.open(url);
    }
  }
}
</script>

<template>
  <div>
    <main-header :selected="5"></main-header>
    <div class="container">
      <div class="head-img-container">
        <img class="head-img" :src="require('/src/assets/images/care/m_4.jpg')" alt>
        <div class="he-black">
        </div>
        <div class="he-text-box">
          <div class="he-text-title">有机成分护肤</div>
          <div class="he-text-content">
            阿茉尔功效护肤产品配方，基于植物提取物，具有天然、温和和有效的特点，在关注植物成分的护肤效果的同时，植物配方更少引起过敏的成分，适合敏感肌肤使用。
          </div>
          <div class="he-text-btn">
            <el-button class="ca-text-button" @click="onPageOpen">水杨酸丨它并不是你想象中那么简单！</el-button>
          </div>
        </div>
      </div>
      <div class="container-body">
        <div class="care-container">
          <div class="care-text">
            <div class="care-title">包裹水杨酸</div>
            <div class="care-text-1">
              包裹水杨酸是一种通过特殊提取技术对水杨酸进行特殊处理的形式，在保持了水杨酸去角质和清洁毛孔的能力,改善油性肌肤和痤疮肌肤的状况的同时，显著降低了水杨酸的刺激性等不良反应，使得产品更加温和更适合敏感肌肤使用。
            </div>
            <div class="care-text-1" v-show="switchText === '收起'">
              包裹技术还能实现水杨酸的缓释效果，这意味着它可以在皮肤上持续作用较长时间，提高产品的护肤效果。并且包裹水杨酸既完全水溶，也可以油溶，这增加了它在化妆品配方中的灵活性和应用范围。由于其特殊的载体系统，在保证温和肤感的同时，能够提高肌肤对活性成分的吸收，增强产品的整体效果。
            </div>
            <div class="care-switch" @click="onMoreClick">
              {{ switchText }}
            </div>
          </div>
          <div class="care-items">
            <div class="care-item">
              <img class="care-item-img" :src="require('/src/assets/images/care/txt-1.svg')" alt>
              <div class="care-item-text">
                极速响应
              </div>
            </div>
            <div class="care-item">
              <img class="care-item-img" :src="require('/src/assets/images/care/txt-2.svg')" alt>
              <div class="care-item-text">
                售后支持
              </div>
            </div>
            <div class="care-item">
              <img class="care-item-img" :src="require('/src/assets/images/care/txt-3.svg')" alt>
              <div class="care-item-text">
                免费试用装
              </div>
            </div>
            <div class="care-item">
              <img class="care-item-img" :src="require('/src/assets/images/care/txt-4.svg')" alt>
              <div class="care-item-text">
                持续跟进
              </div>
            </div>
            <div class="care-item">
              <img class="care-item-img" :src="require('/src/assets/images/care/txt-5.svg')" alt>
              <div class="care-item-text">
                会员中心
              </div>
            </div>
            <div class="care-item">
              <img class="care-item-img" :src="require('/src/assets/images/care/txt-6.svg')" alt>
              <div class="care-item-text">
                增值服务
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="t-1-container">
        <div class="t-1-header">
          <div class="t-1-title">基于大数据分析和人工智能的数字化诊疗服务</div>
          <div class="t-1-tip">
            利用先进的测肤技术，提供细致的肌肤分析，包括但不限于肌肤类型、问题区域、水分含量和油脂分泌等
          </div>
        </div>
        <div class="t-1-body">
          <div class="t-1-card">
            <img class="t-1-cr-img" alt :src="require('/src/assets/images/care/t-1-1.jpg')">
            <div class="t-1-cr-txt-box">
              <div class="t-1-cr-txt-title">
                个性化护肤方案
              </div>
              <div class="t-1-cr-txt">
                基于测肤结果，为用户定制个性化的护肤计划和产品推荐，确保护肤方案的针对性和有效性。
              </div>
            </div>
          </div>
          <div class="t-1-card">
            <img class="t-1-cr-img" alt :src="require('/src/assets/images/care/t-1-2.jpg')">
            <div class="t-1-cr-txt-box">
              <div class="t-1-cr-txt-title">
                全球顶级AI团队
              </div>
              <div class="t-1-cr-txt">
                国际顶级图像识别专家领衔，团队曾获阿里天池医疗AI大赛企业组排名第一。
              </div>
            </div>
          </div>
          <div class="t-1-card">
            <img class="t-1-cr-img" alt :src="require('/src/assets/images/care/t-1-3.jpg')">
            <div class="t-1-cr-txt-box">
              <div class="t-1-cr-txt-title">
                科学研究支持
              </div>
              <div class="t-1-cr-txt">
                由皮肤科医生、化学家、数据分析师和软件开发者等组成的多学科团队，共同推进测肤技术的发展。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="t-2-container">
        <div class="t-1-header">
          <div class="t-1-title">专业的肌肤解决方案</div>
          <div class="t-1-tip">沉淀诊疗大数据，数据驱动产品迭代升级</div>
        </div>
        <div class="t-2-body">
          <img class="t-2-bg-img" alt :src="require('/src/assets/images/care/care-bg.png')">
<!--          <img class="t-2-img" alt :src="require('/src/assets/images/care/t-2-1.jpg')">-->
<!--          <div class="t-2-cr-box">-->
<!--            <div class="t-2-cr">-->
<!--              <div class="t-2-cr-title">医肤科学原理</div>-->
<!--              <div class="t-2-cr-text">-->
<!--                医肤科学原理关注科学护肤原理，通过专业的领域知识，为消费者提供更专业护肤指导、更有效的肌肤护理服务。-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="t-2-cr">-->
<!--              <div class="t-2-cr-title">功效与成分管理</div>-->
<!--              <div class="t-2-cr-text">-->
<!--                综合管理产品的功效与成分，可以确保护肤品的安全性、有效性和合规性，同时提升消费者的使用体验。-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="t-2-cr">-->
<!--              <div class="t-2-cr-title">远程诊断系统</div>-->
<!--              <div class="t-2-cr-text">-->
<!--                远程人工服务，帮助用户获得专业的肌肤问题咨询和个性化的护肤指导。-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="t-2-cr">-->
<!--              <div class="t-2-cr-title">定制商城交易系统</div>-->
<!--              <div class="t-2-cr-text">-->
<!--               根据用户的肤质情况，帮助用户更快捷的购买功效组合商品,一键购买为特定肤质定制的护肤套装。-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="t2-big-btn" @click="skipTo('https://www.aromore.com/field')">
          查阅我们的专业领域
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<style scoped lang="scss">
@import "~@/styles/care/index";
</style>

<script>
/* eslint-disable */
export default {
  name: "acne",
  data() {
    return {
      /**
       * 样例商品
       * example-1;50惠育发
       * example-2;新碧防晒;日用防晒
       * example-3;肌研极润;乳液
       * example-4;手霜及身体乳;手霜
       * example-5;肌研极润;日用防晒
       * example-6;肌研极润;乳液
       * example-7;肌研极润;洁面
       * example-8;肌研极润;卸妆
       */
      // 产品列表数组（模拟获取后端接口）
      productData: [
        {
          id: 2,
          name: "example-2",
          label: "胶原蛋白精华水乳",
          tip: "新一代生物护肤 全天状态稳稳",
          price: 356.00,
          series: 3,
          demand: 1,
          imgURL: require('@/assets/images/acne/item-1-1.jpg'),
          imgURL2: require('@/assets/images/acne/item-1-0.jpg'),
        },
        {
          id: 3,
          name: "example-3",
          label: "阿茉尔祛痘爽肤水",
          tip: "清爽保湿",
          price: 198.00,
          series: 1,
          demand: 2,
          imgURL: require('@/assets/images/acne/item-2-1.jpg'),
          imgURL2: require('@/assets/images/acne/item-2-0.jpg'),
        },
      ],
      // 过滤后的产品数组
      productDataHandled: [],
      // 搜索关键字
      searchKey: "",

    }
  },
  created() {
    this.productDataHandled = this.productData
  },
  methods: {
    /** 选择商品 */
    onProductClick(id) {
      // this.$router.push({
      //   path: '/detail',
      //   query: {
      //     productId: id,
      //   }
      // })
    },
    /** 搜索 */
    onInputChange(str) {
      this.productDataHandled = this.productData.filter((item) => {
        return item.label.includes(str)
      })
    }
  }
}
</script>

<template>
  <div>
    <main-header :selected="2"></main-header>
    <div class="container">
      <div class="head-img-container">
        <img class="head-img" :src="require('/src/assets/images/acne/bg.jpg')" alt>
      </div>
      <div class="search-result">
        祛痘产品
      </div>
      <div class="select-list-container">
        <div class="se-li-list">
          <div class="se-li-card" v-for="item in productDataHandled" :key="item.id" @click="onProductClick(item.id)">
            <div class="se-li-img-box">
              <img class="se-li-img-down" :src="item.imgURL2" alt>
              <img class="se-li-img" :src="item.imgURL" alt>
            </div>
            <div class="card-label">{{item.label}}</div>
            <div class="card-price">￥{{item.price.toFixed(2)}}</div>
            <div class="card-line"></div>
            <div class="card-tip">{{item.tip}}</div>
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<style scoped lang="scss">
@import "~@/styles/acne/index";
</style>

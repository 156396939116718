<script>
/* eslint-disable */
export default {
  name: "consultation",
  data() {
    return {
      bicSelect: 1,
    }
  },
  methods: {
    /** 跳转路径 */
    toPath(url) {
      this.$router.push({
        path: url,
      })
    }
  }
}
</script>

<template>
  <div>
    <main-header :selected="6"></main-header>
    <div class="container">
      <div class="head-img-container">
        <img class="head-img" :src="require('/src/assets/images/diagnosis/remote/bg.jpg')" alt>
        <div class="head-content-box">
          <div class="head-txt-box">
            <div class="head-txt-title">医肤问诊</div>
            <div class="head-txt-content">以医疗对话交互形式，在患者就诊前模拟医生问诊流程，提前采集患者病情信息，生成电子病历格式的预问诊报告及病情分析报告发送给医生，使医生可以提前了解患者病情，同时预问诊报告可直接写入EMR系统，提升门诊就医体验与效率。</div>
            <div class="head-btn-box">
              <el-button class="head-button">申请合作</el-button>
            </div>
          </div>
          <img class="head-img-m" :src="require('/src/assets/images/diagnosis/remote/bg-m.png')" alt>
        </div>
      </div>
    </div>
    <div class="container-body">

      <!-- 大卡片组件 -->
      <div class="bic-container">
        <div class="bi-txt">
          <div class="bi-txt-title">应用场景</div>
          <!--          <div class="bi-txt-tip">示例标题示例标题</div>-->
        </div>
        <div class="bic-card">
          <div class="bic-main">
            <div class="bic-main-item" v-show="bicSelect === 1">
              <img class="bic-img" :src="require('/src/assets/images/platforms/imt-1.jpg')" alt>
              <div class="bic-main-text-box">
                <div class="bic-m-t-title-box">
                  <div class="bic-m-t-line"></div>
                  <div class="bic-m-t-title">智慧医院</div>
                </div>
                <div class="bic-m-t-text-box">
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">就诊前进行患者病情信息收集，提高问诊效率</div>
                  </div>
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">可对接EMR系统，核对无误直接写入系统</div>
                  </div>
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">辅助医生进行患者病情分析及风险评估</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bic-main-item" v-show="bicSelect === 2">
              <img class="bic-img" :src="require('/src/assets/images/platforms/imt-2.jpg')" alt>
              <div class="bic-main-text-box">
                <div class="bic-m-t-title-box">
                  <div class="bic-m-t-line"></div>
                  <div class="bic-m-t-title">分级诊疗</div>
                </div>
                <div class="bic-m-t-text-box">
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">根据采集的信息判断患者病情危重程度实现分流/分级就诊</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bic-main-item" v-show="bicSelect === 3">
              <img class="bic-img" :src="require('/src/assets/images/platforms/imt-3.jpg')" alt>
              <div class="bic-main-text-box">
                <div class="bic-m-t-title-box">
                  <div class="bic-m-t-line"></div>
                  <div class="bic-m-t-title">互联网医院</div>
                </div>
                <div class="bic-m-t-text-box">
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">就诊前进行患者病情信息收集，提高问诊效率</div>
                  </div>
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">在线诊疗诊前病情信息收集</div>
                  </div>
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">可对接EMR系统，诊后核对直接上传病历</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bic-main-item" v-show="bicSelect === 4">
              <img class="bic-img" :src="require('/src/assets/images/platforms/imt-4.jpg')" alt>
              <div class="bic-main-text-box">
                <div class="bic-m-t-title-box">
                  <div class="bic-m-t-line"></div>
                  <div class="bic-m-t-title">在线诊疗平台</div>
                </div>
                <div class="bic-m-t-text-box">
                  <div class="bic-m-t-item">
                    <div class="bic-m-t-text-before"></div>
                    <div class="bic-m-t-text-content">问诊前进行患者病情信息收集，提高在线问诊效率</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bic-aside">
            <div class="bic-button" @click="bicSelect = 1" :class="{'bic-button-selected': bicSelect === 1}">
              <p class="bic-button-txt">智慧医院</p>
            </div>
            <div class="bic-button" @click="bicSelect = 2" :class="{'bic-button-selected': bicSelect === 2}">
              <p class="bic-button-txt">分级诊疗</p>
            </div>
            <div class="bic-button" @click="bicSelect = 3" :class="{'bic-button-selected': bicSelect === 3}">
              <p class="bic-button-txt">互联网医院</p>
            </div>
            <div class="bic-button" @click="bicSelect = 4" :class="{'bic-button-selected': bicSelect === 4}">
              <p class="bic-button-txt">在线诊疗平台</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 大图片组件 -->
      <!--      <div class="big-img-box">-->
      <!--        <div class="bi-txt">-->
      <!--          <div class="bi-txt-title">示例标题</div>-->
      <!--          <div class="bi-txt-tip">示例标题示例标题</div>-->
      <!--        </div>-->
      <!--        <img class="bi-img" :src="require('/src/assets/images/ai-doctor/big-img-2.jpg')" alt>-->
      <!--      </div>-->

      <!-- 左右图文组件 -->
      <div class="img-txt-container-left">
        <div class="bi-txt">
          <div class="bi-txt-title">产品介绍</div>
          <!--          <div class="bi-txt-tip">示例标题示例标题</div>-->
        </div>
        <div class="t-2-body">
          <img class="t-2-img" alt :src="require('/src/assets/images/platforms/img-1.jpg')">
          <div class="t-2-cr-box">
            <!--            <div class="t-2-cr">-->
            <!--              <div class="t-2-cr-title">示例标题</div>-->
            <!--              <div class="t-2-cr-text">-->
            <!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
            <!--              </div>-->
            <!--            </div>-->
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">智能交互采集完整病情</div>
              </div>
              <div class="t-2-txt-content">对话机器人模拟医生问诊流程，智能采集患者病情信息，包括主诉、现病史、既往史、过敏史、月经婚育史、个人史等信息，并根据患者回复动态追问，帮助医生深入了解患者情况。</div>
              <div class="head-btn-box">
                <el-button class="head-button" >了解详情</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="t-2-body t-2-body-justify">
          <div class="t-2-cr-box">
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">多模态交互样式</div>
              </div>
              <div class="t-2-txt-content">支持患者口语化表达主诉，可上传就诊病历图片，支持选择既往就诊记录信息，多模态交互，提升交互体验与信息采集准确度。</div>
              <div class="head-btn-box">
                <el-button class="head-button" >了解详情</el-button>
              </div>
            </div>
          </div>
          <img class="t-2-img" alt :src="require('/src/assets/images/platforms/img-2.jpg')">
        </div>
        <div class="t-2-body t-2-body-justify">
          <img class="t-2-img" alt :src="require('/src/assets/images/platforms/img-3.jpg')">
          <div class="t-2-cr-box">
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">异常危急即时提醒</div>
              </div>
              <div class="t-2-txt-content">对话交互过程中，提醒患者异常、危急情况并给出处理建议。</div>
              <div class="head-btn-box">
                <el-button class="head-button" >了解详情</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="t-2-body t-2-body-justify">
          <div class="t-2-cr-box">
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">规范EMR生成与一键导入</div>
              </div>
              <div class="t-2-txt-content">信息采集完成后，自动生成规范化的电子病历文本 ；医生确认无误可一键导入EMR系统，有效节省医生书写病历时间。</div>
              <div class="head-btn-box">
                <el-button class="head-button" >了解详情</el-button>
              </div>
            </div>
          </div>
          <img class="t-2-img" alt :src="require('/src/assets/images/platforms/img-4.jpg')">
        </div>
        <div class="t-2-body t-2-body-justify">
          <img class="t-2-img" alt :src="require('/src/assets/images/platforms/img-5.jpg')">
          <div class="t-2-cr-box">
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">医生端丰富信息呈现</div>
              </div>
              <div class="t-2-txt-content">医生可便捷查看基于患者对话生成的规范电子病历，患者的预问诊病情分析报告，包括症状评估、可视化病情展示等内容，方便医生快速定位重点信息。</div>
              <div class="head-btn-box">
                <el-button class="head-button" >了解详情</el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="t-2-body t-2-body-justify">
          <div class="t-2-cr-box">
            <div class="t-2-txt-box">
              <div class="t-2-title-box">
                <div class="t-2-line"></div>
                <div class="t-2-txt-title">产品运营数据可视化展示</div>
              </div>
              <div class="t-2-txt-content">系统整体运营情况可视化展示，包括使用人次、使用率、完成率、初诊和复诊分布情况等。</div>
              <div class="head-btn-box">
                <el-button class="head-button" >了解详情</el-button>
              </div>
            </div>
          </div>
          <img class="t-2-img" alt :src="require('/src/assets/images/platforms/img-6.jpg')">
        </div>
      </div>

      <!--      <div class="img-txt-container-left">-->
      <!--        <div class="t-2-body">-->
      <!--          <div class="t-2-cr-box">-->
      <!--            <div class="t-2-cr">-->
      <!--              <div class="t-2-cr-title">示例标题</div>-->
      <!--              <div class="t-2-cr-text">-->
      <!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="t-2-cr">-->
      <!--              <div class="t-2-cr-title">示例标题</div>-->
      <!--              <div class="t-2-cr-text">-->
      <!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="t-2-cr">-->
      <!--              <div class="t-2-cr-title">示例标题</div>-->
      <!--              <div class="t-2-cr-text">-->
      <!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
      <!--              </div>-->
      <!--            </div>-->
      <!--            <div class="t-2-cr">-->
      <!--              <div class="t-2-cr-title">示例标题</div>-->
      <!--              <div class="t-2-cr-text">-->
      <!--                示例标题示例标题，示例标题示例标题，示例标题示例标题，示例标题示例标题。-->
      <!--              </div>-->
      <!--            </div>-->
      <!--          </div>-->
      <!--          <img class="t-2-img" alt :src="require('/src/assets/images/diagnosis/remote/imt-2.jpg')">-->
      <!--        </div>-->
      <!--      </div>-->

      <!-- 卡片组件 -->
      <div class="card-container">
        <div class="bi-txt">
          <div class="bi-txt-title">产品优势</div>
          <!--          <div class="bi-txt-tip">示例标题示例标题</div>-->
        </div>
        <div class="card-box">
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/platforms/cr-1.png')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">行业领先医疗知识图谱支撑</div>
                <p>对话交互底层引擎基于业内顶级的医疗知识图谱，包含20类医疗实体、200种关系及千万条边，覆盖6000+疾病, 强大的知识基础保障对话交互的医学规范性和生成电子病历的质量</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/platforms/cr-2.png')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">专业精确</div>
                <p>支持客户配置特殊科室信息，支持定制个性化对话交互流程</p>
              </div>
            </div>
          </el-card>
          <el-card>
            <div class="cr-body">
              <img class="cr-img" :src="require('/src/assets/images/platforms/cr-3.png')" alt>
              <div class="cr-txt-box">
                <div class="cr-txt-title">多家顶级医院机构共同打磨</div>
                <p>左医预问诊系统已在多家顶级医院机构落地应用，与临床专家紧密协作，持续打磨系统、迭代优化效果</p>
              </div>
            </div>
          </el-card>
        </div>
      </div>

    </div>
    <main-footer></main-footer>
  </div>
</template>

<style scoped lang="scss">
@import "~@/styles/platforms/consultation";
</style>
